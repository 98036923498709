/* b0e5e1a8003553e0562e466392f6261aa9eaccc5
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateEventMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  title: Types.Scalars['String'];
  visibility: Types.NewEventVisibilityEnum;
  location?: Types.InputMaybe<Types.Scalars['String']>;
  description?: Types.InputMaybe<Types.Scalars['String']>;
  attendeesEmailAddresses: Array<Types.Scalars['String']> | Types.Scalars['String'];
  calendarId: Types.Scalars['String'];
  startAt: Types.Scalars['DateTimeRFC3339'];
  endAt: Types.Scalars['DateTimeRFC3339'];
  addVideoConference?: Types.InputMaybe<Types.NewVideoConferenceProvider>;
  colorFamily?: Types.InputMaybe<Types.ColorFamily>;
  isAllDay?: Types.InputMaybe<Types.Scalars['Boolean']>;
  doneAt?: Types.InputMaybe<Types.Scalars['DateTimeRFC3339']>;
  recurrenceRules?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  notifyGuests?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type CreateEventMutation = { __typename?: 'mutation_root', createEvent: { __typename?: 'CreateEventOutput', success: boolean, willNotify: boolean, event?: { __typename?: 'NewEvent', calendarId: string, description?: string | null, title: string, id: string, compoundId: string, createdAt: string, doneAt?: string | null, doneBy?: string | null, location?: string | null, colorFamily?: Types.ColorFamily | null, rsvp: Types.NewEventRsvpEnum, isOwnEvent: boolean, canEdit: boolean, isSelfAsAttendee: boolean, belongsToUserCalendar: boolean, eventType?: string | null, recurringEventId?: string | null, recurrenceRules?: Array<string> | null, startAt: any, endAt: any, isAllDay: boolean, prevStartAt: any, prevEndAt: any, status: string, visibility: Types.NewEventVisibilityEnum, updatedAt: any, attendees: Array<{ __typename?: 'NewEventAttendee', avatar?: string | null, displayName?: string | null, firstName?: string | null, id: string, lastName?: string | null, RSVP: Types.NewEventRsvpEnum, email: string, organizer: boolean, responseStatus: Types.NewEventAttendeeResponseStatusEnum }>, creator?: { __typename?: 'EventCreator', id?: string | null, self?: boolean | null } | null, videoConferences: Array<{ __typename?: 'NewEventVideoConference', link: string, provider: Types.NewVideoConferenceProvider }> } | null } };

export type EventFragmentFragment = { __typename?: 'NewEvent', calendarId: string, description?: string | null, title: string, id: string, compoundId: string, createdAt: string, doneAt?: string | null, doneBy?: string | null, location?: string | null, colorFamily?: Types.ColorFamily | null, rsvp: Types.NewEventRsvpEnum, isOwnEvent: boolean, canEdit: boolean, isSelfAsAttendee: boolean, belongsToUserCalendar: boolean, eventType?: string | null, recurringEventId?: string | null, recurrenceRules?: Array<string> | null, startAt: any, endAt: any, isAllDay: boolean, prevStartAt: any, prevEndAt: any, status: string, visibility: Types.NewEventVisibilityEnum, updatedAt: any, attendees: Array<{ __typename?: 'NewEventAttendee', avatar?: string | null, displayName?: string | null, firstName?: string | null, id: string, lastName?: string | null, RSVP: Types.NewEventRsvpEnum, email: string, organizer: boolean, responseStatus: Types.NewEventAttendeeResponseStatusEnum }>, creator?: { __typename?: 'EventCreator', id?: string | null, self?: boolean | null } | null, videoConferences: Array<{ __typename?: 'NewEventVideoConference', link: string, provider: Types.NewVideoConferenceProvider }> };

export const EventFragmentFragmentDoc = gql`
    fragment EventFragment on NewEvent {
  attendees {
    avatar
    displayName
    firstName
    id
    lastName
    RSVP
    email
    organizer
    responseStatus
  }
  calendarId
  description
  title
  id
  compoundId
  createdAt
  creator {
    id
    self
  }
  doneAt
  doneBy
  location
  colorFamily
  rsvp
  isOwnEvent
  canEdit
  isSelfAsAttendee
  belongsToUserCalendar
  eventType
  recurringEventId
  recurrenceRules
  startAt
  endAt
  isAllDay
  prevStartAt
  prevEndAt
  updatedAt: updated
  status
  videoConferences {
    link
    provider
  }
  visibility
}
    `;
export const CreateEventDocument = gql`
    mutation CreateEvent($id: String!, $title: String!, $visibility: NewEventVisibilityEnum!, $location: String, $description: String, $attendeesEmailAddresses: [String!]!, $calendarId: String!, $startAt: DateTimeRFC3339!, $endAt: DateTimeRFC3339!, $addVideoConference: NewVideoConferenceProvider, $colorFamily: ColorFamily, $isAllDay: Boolean, $doneAt: DateTimeRFC3339, $recurrenceRules: [String!], $notifyGuests: Boolean) {
  createEvent(
    objects: {id: $id, title: $title, description: $description, location: $location, calendarId: $calendarId, startAt: $startAt, endAt: $endAt, visibility: $visibility, attendeesEmailAddresses: $attendeesEmailAddresses, addVideoConference: $addVideoConference, colorFamily: $colorFamily, isAllDay: $isAllDay, doneAt: $doneAt, recurrenceRules: $recurrenceRules, notifyGuests: $notifyGuests}
  ) {
    success
    willNotify
    event {
      ...EventFragment
    }
  }
}
    ${EventFragmentFragmentDoc}`;

export function useCreateEventMutation() {
  return Urql.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument);
};