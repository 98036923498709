import {
  ColorFamily,
  NewEventAttendeeResponseStatusEnum,
  NewEventRsvpEnum,
} from '@graphql-types@';
import { userEmailAtom } from 'hooks/auth/authAtoms';
import { atom } from 'jotai';
import { atomWithStorage, useAtomCallback, useAtomValue } from 'jotai/utils';
import { uniq } from 'lodash';
import Router from 'next/router';
import { useCallback, useMemo } from 'react';
import { Attendee } from 'types/events';
import { useCalendars, ownCalendarsAtom } from './useCalendars';
import useFavorites from './useFavorites';

interface VisibleCalendar {
  email: string;
  displayName: string;
  colorFamily: ColorFamily;
}

const allVisibleCalendarsAtom = atomWithStorage<string[]>(
  'allVisibleCalendarsAtom',
  []
);
export const hideOwnCalendarAtom = atom<boolean>(false);
export const guestCalendarsAtom = atom<string[]>([]);

export const calendarsWithDefaultAtom = atom((get) => {
  const currentCalendars = get(allVisibleCalendarsAtom);
  const guestCalendars = get(guestCalendarsAtom);
  const hideOwnCalendar = get(hideOwnCalendarAtom);
  const userEmail = get(userEmailAtom);

  if (hideOwnCalendar) {
    // used to allow toggling own calendar off and on from the event guests section
    return uniq([
      ...currentCalendars.filter((e) => e !== userEmail),
      ...guestCalendars,
    ]);
  }
  if ((currentCalendars.length > 0 || guestCalendars.length > 0) && userEmail) {
    return uniq([userEmail, ...currentCalendars, ...guestCalendars]);
  }
  return userEmail ? [userEmail] : [];
});

export const visibleContactsCalendarsAtom = atom((get) => {
  const currentCalendars = get(allVisibleCalendarsAtom);
  const ownCalendars = get(ownCalendarsAtom);

  return currentCalendars.filter(
    (cal) => !ownCalendars.some((ownCal) => ownCal.id === cal)
  );
});

export function getCalendarsAsAttendees(
  visibleCalendars: VisibleCalendar[]
): Attendee[] {
  // Exclude your own calendars

  return visibleCalendars.map((calendar) => ({
    id: calendar.email,
    email: calendar.email,
    RSVP: NewEventRsvpEnum.Unknown,
    responseStatus: NewEventAttendeeResponseStatusEnum.NeedsAction,
    organizer: false,
    displayName: calendar.displayName,
  }));
}

export function useVisibleCalendarIds(): string[] {
  return useAtomValue(calendarsWithDefaultAtom);
}

export function useVisibleCalendars(): VisibleCalendar[] {
  const favorites = useFavorites();
  const calendars = useCalendars();
  const visibleCalendars = useVisibleCalendarIds();

  return useMemo(
    () =>
      visibleCalendars.map<VisibleCalendar>((email) => ({
        email,
        colorFamily:
          calendars.find((calendar) => calendar.id === email)?.colorFamily ||
          ColorFamily.Gray,
        displayName:
          favorites.find((favorite) => favorite.emailAddress === email)
            ?.displayName || email,
      })),
    [visibleCalendars, favorites, calendars]
  );
}

export function useSetVisibleCalendars() {
  const resetContactsCalendars = useAtomCallback(
    useCallback((get, set) => {
      const ownCalendars = get(ownCalendarsAtom);
      set(allVisibleCalendarsAtom, (visibleCalendars) => {
        return visibleCalendars.filter((cal) =>
          ownCalendars.some((ownCal) => ownCal.id === cal)
        );
      });
    }, [])
  );

  const addCalendar = useAtomCallback(
    useCallback((_, set, calendarIds: string | string[]) => {
      // Don't push if rendered from the server (or Jest).
      if (typeof Router.push !== 'undefined') Router.push('/');

      set(allVisibleCalendarsAtom, (prevCalendarIds) => {
        if (typeof calendarIds === 'string') {
          if (prevCalendarIds.find((id) => id === calendarIds)) {
            return prevCalendarIds;
          }
          return [...prevCalendarIds, calendarIds];
        }

        return calendarIds.concat(...calendarIds);
      });
    }, [])
  );

  const removeCalendar = useAtomCallback(
    useCallback((_, set, calendarIds: string | string[]) => {
      set(allVisibleCalendarsAtom, (calendars) => {
        if (typeof calendarIds === 'string') {
          return calendars.filter((id) => id !== calendarIds);
        }

        return calendars.filter((id) => !calendarIds.includes(id));
      });
    }, [])
  );

  return {
    addCalendar,
    removeCalendar,
    resetContactsCalendars,
  };
}

export function useSetVisibleGuestCalendars() {
  const { removeCalendar } = useSetVisibleCalendars();

  const addGuestCalendar = useAtomCallback(
    useCallback((get, set, calendarId: string) => {
      const userCalendarId = get(userEmailAtom);
      if (userCalendarId === calendarId) {
        set(hideOwnCalendarAtom, false);
        return;
      }

      const guestCalendars = get(guestCalendarsAtom);
      if (guestCalendars.some((e) => e === calendarId)) return;

      // Don't push if rendered from the server (or Jest).
      if (typeof Router.push !== 'undefined') Router.push('/');
      set(guestCalendarsAtom, (prevCalendarIds) => [
        ...prevCalendarIds,
        calendarId,
      ]);
    }, [])
  );

  const removeGuestCalendar = useAtomCallback(
    useCallback(
      (get, set, calendarId: string) => {
        const userCalendarId = get(userEmailAtom);
        if (calendarId === userCalendarId) {
          set(hideOwnCalendarAtom, true);
          return;
        }

        removeCalendar(calendarId);
        set(guestCalendarsAtom, (prevCalendarIds) =>
          prevCalendarIds.filter((id) => id !== calendarId)
        );
      },
      [removeCalendar]
    )
  );
  const resetGuestCalendars = useAtomCallback(
    useCallback((_, set) => {
      set(hideOwnCalendarAtom, false);
      set(guestCalendarsAtom, []);
    }, [])
  );

  return {
    addGuestCalendar,
    removeGuestCalendar,
    resetGuestCalendars,
  };
}
