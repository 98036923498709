import { Theme_Enum } from '@graphql-types@';
import { CookieSerializeOptions } from 'cookie';
import useHotkey from 'hooks/useHotkey';
import { useUpdatePreference, useUserTheme } from 'hooks/usePreferences';
import { useAtomCallback } from 'jotai/utils';
import { DateTime } from 'luxon';
import { parseCookies, setCookie } from 'nookies';
import React, { useCallback, useEffect, useState } from 'react';
import { PreferenceName } from 'types/preference';
import usePrevious from '../hooks/usePrevious';
import { COOKIE_NAME_THEME } from '../utils/constants';
import createCtx from './createCtx';

const cookieConfig: CookieSerializeOptions = {
  expires: DateTime.now().plus({ years: 5 }).toJSDate(),
  sameSite: 'lax',
};

type TThemeContext = {
  theme: Theme_Enum | undefined;
};

export const [useThemeContext, ThemeContext] = createCtx<TThemeContext>();
export const getOppositeTheme = (theme: Theme_Enum | undefined) =>
  theme === Theme_Enum.Light ? Theme_Enum.Dark : Theme_Enum.Light;

export const ThemeProvider: React.FC<{ ssrTheme?: Theme_Enum }> = ({
  children,
  ssrTheme,
}) => {
  const systemTheme = getSystemTheme();
  const userTheme = useUserTheme();
  const [theme, setTheme] = useState<Theme_Enum | undefined>(
    () => ssrTheme || userTheme || systemTheme
  );
  const oldTheme = usePrevious(theme);
  useEffect(() => {
    if (typeof window !== 'undefined' && theme) {
      const htmlClassList = document.documentElement.classList;
      const themeMetaTag = document.querySelector('meta[name="theme-color"]');

      if (themeMetaTag) {
        themeMetaTag.setAttribute(
          'content',
          theme === Theme_Enum.Dark ? '#262626' : '#fafafa'
        );
      }
      htmlClassList.remove(oldTheme === Theme_Enum.Dark ? 'dark' : 'light');
      htmlClassList.add(theme === Theme_Enum.Light ? 'light' : 'dark');

      if (theme !== oldTheme) {
        setCookie(null, COOKIE_NAME_THEME, theme, cookieConfig);
      }
    }
  }, [theme, oldTheme]);

  useEffect(() => {
    if (userTheme && userTheme !== oldTheme) {
      changeTheme(userTheme);
    } else if (oldTheme && !userTheme) {
      changeTheme(systemTheme);
      setCookie(null, COOKIE_NAME_THEME, 'SYSTEM', cookieConfig);
    }
  }, [userTheme, oldTheme, systemTheme]);

  useEffect(() => {
    const systemPreferenceTheme = window.matchMedia(
      '(prefers-color-scheme: dark)'
    );
    const setThemeFromSystem = () => changeTheme(getSystemTheme());
    if (!userTheme && systemPreferenceTheme) {
      systemPreferenceTheme.addEventListener('change', setThemeFromSystem);
    }
    return () => {
      systemPreferenceTheme?.removeEventListener('change', setThemeFromSystem);
    };
  }, [userTheme]);

  function changeTheme(theme: Theme_Enum) {
    setCookie(null, COOKIE_NAME_THEME, theme, cookieConfig);
    setTheme(theme);
  }

  return <ThemeContext value={{ theme }}>{children}</ThemeContext>;
};

export function getCookieTheme(): Theme_Enum | undefined {
  const cookies = parseCookies();
  switch (cookies[COOKIE_NAME_THEME]) {
    case Theme_Enum.Light:
      return Theme_Enum.Light;
    case Theme_Enum.Dark:
      return Theme_Enum.Dark;
    default:
      return undefined;
  }
}

function getSystemTheme(): Theme_Enum {
  if (typeof window === 'undefined') {
    return Theme_Enum.Light;
  }
  return !!window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
    ? Theme_Enum.Dark
    : Theme_Enum.Light;
}

export function ToggleThemeShortcut() {
  const updatePreference = useUpdatePreference();
  const { theme } = useThemeContext();
  const swicthTheme = () => {
    updatePreference(
      PreferenceName.Theme,
      theme === Theme_Enum.Light ? Theme_Enum.Dark : Theme_Enum.Light
    );
  };

  useHotkey('option+m', 'all', swicthTheme);

  return null;
}
