import type { IpcRendererEvent } from 'electron';
import { useEffect, useRef, useMemo } from 'react';

function useElectronIpc() {
  const ipcRenderer = useRef(
    typeof window !== 'undefined' ? window.interop?.ipc : undefined
  );

  const isElectron = useMemo(() => !!ipcRenderer.current, [ipcRenderer]);

  useEffect(() => {
    ipcRenderer.current = window.interop?.ipc;
  }, []);

  return { ipc: ipcRenderer.current, isElectron };
}

interface IpcEventHandler {
  <T = any>(event: IpcRendererEvent, data: T): void;
}

export function useElectronIpcEvent(
  eventName: string,
  handler: IpcEventHandler
) {
  const { ipc } = useElectronIpc();

  useEffect(() => {
    ipc?.on(eventName, handler);

    return () => {
      ipc?.off(eventName, handler);
    };
  }, [ipc, eventName, handler]);
}

export default useElectronIpc;
