/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { contactCalendarLoadingFamily } from 'hooks/contacts/useIsContactCalendarLoading';
import useCalendar from 'hooks/useCalendar';
import { userAtom } from 'hooks/user/userAtoms';
import { timezoneAtom } from 'hooks/useTimeZone';
import { Getter, Setter } from 'jotai';
import { useAtomCallback, useAtomValue, useUpdateAtom } from 'jotai/utils';
import { keyBy } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { IGridEvent } from 'types/events';
import { RequestPolicy } from 'urql';
import {
  getPreferredColorFamilyRanking,
  reverseColorFamilyRankingAtom,
} from 'utils/calendarColorFamily';
import { dateIsBetween } from 'utils/time';
import { sleep } from 'utils/toolbox';
import { EventsQuery } from '../../graphql/queries/events.graphql';
import { fetchSingleEventQuery, serverEventsQuery } from './api/eventsApi';
import {
  eventIdsPoolAtom,
  gridEventsFamily,
  interactionOnlyEventsFamily,
  isGridEventsReadyAtom,
  nextWeekEventsAtom,
  optimisticEventsFamily,
  serverEventsAtomFamily,
  visibleEventsAtom,
  visibleEventsIdsAtom,
} from './eventAtoms';
import { removeEventFromPool } from './helpers/eventAtomsHelpers';
import {
  formatServerEvent,
  getFirstAndLastEvents,
} from './helpers/eventsHelpers';

/**
 * Returns the currently selected week events
 */
export function useVisibleEvents(): IGridEvent[] {
  return useAtomValue(visibleEventsAtom);
}

export function useVisibleEventsIds(): string[] {
  return useAtomValue(visibleEventsIdsAtom);
}

export function useNextWeekEvents(): IGridEvent[] {
  return useAtomValue(nextWeekEventsAtom);
}

/**
 * Get a single event
 */
export function useGridEvent(id: string): IGridEvent | null {
  return useAtomValue(gridEventsFamily(id));
}

export function useInteractionOnlyEvent(id: string) {
  return useAtomValue(interactionOnlyEventsFamily(id));
}

export function useGetGridEvent() {
  return useAtomCallback((get, _, id: string) => get(gridEventsFamily(id)));
}

export function useIsGridEventsReady() {
  return useAtomValue(isGridEventsReadyAtom);
}

type ServerEvents = EventsQuery['event']['events'];

export function useFetchVisibleWeekEvents({
  plusWeek,
  minusWeek,
}: {
  requestPolicy?: RequestPolicy;
  plusWeek?: number;
  minusWeek?: number;
} = {}) {
  const { startDate, endDate } = useCalendar();
  const fetchEvents = useFetchEvents();
  const fetchVisibleWeekEvents = useCallback(
    (calendarId: string) => {
      fetchEvents({
        startAt: startDate.minus({ weeks: minusWeek ?? 0 }),
        endAt: endDate.plus({ weeks: plusWeek ?? 0 }),
        calendarId,
      });
    },
    [endDate, fetchEvents, minusWeek, plusWeek, startDate]
  );
  return fetchVisibleWeekEvents;
}

export function useFetchEvents() {
  const fetchEvents = useAtomCallback(_fetchEvents);
  const storeEvents = useAtomCallback(_storeEvents);
  const removeDeletedEvents = useAtomCallback(_removeDeletedEvents);
  const storeEventIds = useAtomCallback(_storeEventIds);
  const setReady = useUpdateAtom(isGridEventsReadyAtom);
  const updateColorRanking = useAtomCallback(
    (get, set, args: { events: ServerEvents; calendarId: string }) => {
      const user = get(userAtom);
      const isUserCalendar = args.calendarId === user?.email;
      const isEmptyColorRanking =
        get(reverseColorFamilyRankingAtom).length === 0;

      if (isUserCalendar && isEmptyColorRanking) {
        set(
          reverseColorFamilyRankingAtom,
          getPreferredColorFamilyRanking(args.events).reverse()
        );
      }
    }
  );

  return useCallback(
    async (params: {
      calendarId: string;
      startAt: DateTime;
      endAt: DateTime;
    }) => {
      const serverEvents = await fetchEvents(params);
      if (!serverEvents) {
        return;
      }

      storeEvents(serverEvents);
      storeEventIds({
        events: serverEvents,
        params,
      });
      removeDeletedEvents({ serverEvents, calendarId: params.calendarId });
      setReady(true);
      updateColorRanking({
        calendarId: params.calendarId,
        events: serverEvents,
      });
    },
    [
      fetchEvents,
      storeEvents,
      removeDeletedEvents,
      storeEventIds,
      setReady,
      updateColorRanking,
    ]
  );
}

// ========== UTILS ============ //

interface FetchEventsProps {
  startAt: DateTime;
  endAt: DateTime;
  calendarId: string;
}

const _fetchEvents = async (
  get: Getter,
  set: Setter,
  { calendarId, startAt, endAt }: FetchEventsProps
) => {
  const currentWeekEvents = get(eventIdsPoolAtom);

  const isInitialLoad = currentWeekEvents.size === 0;
  const loadingAtom = contactCalendarLoadingFamily(calendarId);
  set(loadingAtom, isInitialLoad);

  // fetch the events, then normalize them
  const params = {
    startAt: startAt,
    endAt: endAt,
    calendarIds: [calendarId],
    isUserCalendar: calendarId === get(userAtom)?.email,
  };

  const serverEvents = await serverEventsQuery(params);
  set(loadingAtom, false);
  /**
   * TODO: set serverEvents.calendars with accessRoles somewhere
   * when needed.
   */
  return serverEvents;
};

const _storeEvents = (get: Getter, set: Setter, events: ServerEvents) => {
  const user = get(userAtom);
  const timezone = get(timezoneAtom);

  if (!user) {
    return;
  }

  events.forEach((event) => {
    const existingEvent = get(serverEventsAtomFamily(event.id));
    if (event.status === 'cancelled' && existingEvent) {
      set(optimisticEventsFamily(event.id), { status: 'cancelled' });
      return;
    }
    const isServerEventOutdated =
      existingEvent?.updatedAt &&
      DateTime.fromISO(event.updatedAt).toMillis() <
        existingEvent.updatedAt.toMillis();
    if (event.status === 'cancelled' || isServerEventOutdated) {
      return;
    }
    set(
      serverEventsAtomFamily(event.id),
      formatServerEvent(event, user.email, timezone)
    );
  });
};

const _storeEventIds = (
  _get: Getter,
  set: Setter,
  {
    events,
  }: {
    events: ServerEvents;
    params: FetchEventsProps;
  }
) => {
  set(eventIdsPoolAtom, (previousServerEventIds) => {
    events.forEach((event) => {
      if (event.status === 'cancelled') {
        previousServerEventIds.delete(event.id);
      } else {
        previousServerEventIds.add(event.id);
      }
    });
    return new Set(previousServerEventIds);
  });
};

function _removeDeletedEvents(
  get: Getter,
  set: Setter,
  {
    serverEvents,
    calendarId,
  }: { serverEvents: ServerEvents; calendarId: string }
) {
  const userTimezone = get(timezoneAtom);
  const formattedServerEvents = serverEvents.map((event) =>
    formatServerEvent(event, calendarId, userTimezone)
  );
  const firstAndLast = getFirstAndLastEvents(formattedServerEvents);
  if (!firstAndLast) {
    return;
  }
  const { firstEvent, lastEvent } = firstAndLast;
  const serverEventsMap = keyBy(formattedServerEvents, 'id');
  const potentialDeletedEventIds = [...get(eventIdsPoolAtom)].filter(
    (eventId) => {
      const event = get(gridEventsFamily(eventId));
      if (!event) {
        return false;
      }
      return (
        event.calendarId === calendarId &&
        !serverEventsMap[eventId] &&
        dateIsBetween({
          date: event.startAt,
          min: firstEvent.startAt,
          max: lastEvent.startAt,
        })
      );
    }
  );

  potentialDeletedEventIds.forEach((eventId) => {
    removeIfEventIsDeleted(get, set, eventId);
  });
}

async function removeIfEventIsDeleted(
  get: Getter,
  set: Setter,
  eventId: string
) {
  const event = get(gridEventsFamily(eventId));
  if (!event || event.status === 'cancelled' || event.isDraft) {
    return;
  }
  // Delay the fetch in order to make sure all creation request have landed
  await sleep(5000);
  const serverEvent = await fetchSingleEventQuery({
    calendarId: event.calendarId,
    eventId,
  });
  if (!serverEvent || serverEvent.status === 'cancelled') {
    removeEventFromPool(set, eventId);
  }
}
