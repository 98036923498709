import { useAtomCallback } from 'jotai/utils';
import { ToastData } from 'joy/Toasts/types';
import { useCallback } from 'react';
import { toastsAtom } from './atoms';

interface ReturnProps {
  add: (toast: ToastData) => void;
  remove: (id: string) => void;
  clear: () => void;
}

function withDefault(toast: ToastData): ToastData {
  return {
    orientation: 'vertical',
    autoHide: true,
    ...toast,
  };
}

export default function useToaster(): ReturnProps {
  const add = useAtomCallback(
    useCallback((_, set, toast: ToastData) => {
      set(toastsAtom, (toasts) => [
        ...toasts.filter((t) => t.id !== toast.id),
        withDefault(toast),
      ]);
    }, [])
  );

  const remove = useAtomCallback(
    useCallback((_, set, id: string) => {
      set(toastsAtom, (toasts) => toasts.filter((toast) => toast.id !== id));
    }, [])
  );

  const clear = useAtomCallback(
    useCallback((_, set) => {
      set(toastsAtom, []);
    }, [])
  );

  return { add, remove, clear };
}
