/* f24d10cf6d20cd047ac337d1b692705856d26d7b
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InsertPreferencesMutationVariables = Types.Exact<{
  object: Types.UserPreferences_Insert_Input;
}>;


export type InsertPreferencesMutation = { __typename?: 'mutation_root', insert_userPreferences_one?: { __typename?: 'userPreferences', id: any, userId: string, todoPrivacy: boolean, videoProvider?: Types.VideoProvider_Enum | null, theme?: Types.Theme_Enum | null, calendarStartsOn?: Types.CalendarStartsOn_Enum | null, defaultDuration: number, zoomLink?: string | null, dateFormat?: Types.DateFormat_Enum | null, ui24HourClock: boolean, aroundLink?: string | null, defaultEventColor: Types.ColorFamilyEnum_Enum } | null };


export const InsertPreferencesDocument = gql`
    mutation InsertPreferences($object: userPreferences_insert_input!) {
  insert_userPreferences_one(
    object: $object
    on_conflict: {constraint: userPreferences_userId_key, update_columns: []}
  ) {
    id
    userId
    todoPrivacy
    videoProvider
    theme
    calendarStartsOn
    defaultDuration
    zoomLink
    dateFormat
    ui24HourClock
    aroundLink
    defaultEventColor
  }
}
    `;

export function useInsertPreferencesMutation() {
  return Urql.useMutation<InsertPreferencesMutation, InsertPreferencesMutationVariables>(InsertPreferencesDocument);
};