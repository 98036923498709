import { useUser } from 'hooks/auth/authAtoms';
import { useSyncContactsMutation } from 'graphql/mutations/SyncContacts.graphql';
import { useUpdateContactByPkMutation } from 'graphql/mutations/UpdateContact.graphql';
import { ContactsQuery } from 'graphql/queries/contacts.graphql';
import { useContactsSubscription } from 'graphql/subscriptions/contacts.graphql';
import { atom, useAtom } from 'jotai';
import { useAtomCallback, useAtomValue } from 'jotai/utils';
import { useCallback, useEffect } from 'react';
import { useIsGridEventsReady } from './events/useGridEvents';

type Contact = ContactsQuery['new_contact'][0];

const contactsAtom = atom<Contact[]>([]);

export function useContactsValue() {
  return useAtomValue(contactsAtom);
}

export function useSyncGoogleContacts() {
  const [, syncContactsMutation] = useSyncContactsMutation();
  return syncContactsMutation;
}

export function useContactUpdate(email: string) {
  const [, updateContactMutation] = useUpdateContactByPkMutation();

  const updateContact = useAtomCallback(
    useCallback(
      async (get, set, update: Partial<Contact>) => {
        if (email === '') return;

        const contacts = get(contactsAtom);
        const contact = contacts.find((c) => c.emailAddress === email);

        set(
          contactsAtom,
          contacts.map((c) =>
            c.emailAddress === email ? { ...c, ...update } : c
          )
        );

        await updateContactMutation({
          id: contact?.id,
          _set: update,
        });
      },
      [email, updateContactMutation]
    )
  );

  return updateContact;
}

export function useContact(email: string): Contact | undefined {
  const contacts = useAtomValue(contactsAtom);
  return contacts.find((c) => c.emailAddress === email);
}

export function SyncContacts(): null {
  useSyncContacts();
  return null;
}

function useSyncContacts() {
  const user = useUser();
  const areGridEventsReady = useIsGridEventsReady();
  const pause = user?.didAuth === false || !user?.email || !areGridEventsReady;
  const [contacts, setContacts] = useAtom(contactsAtom);
  const [, syncContactsMutation] = useSyncContactsMutation();

  // const pause = Boolean(user?.didAuth) === false || !isEventsQueryFetched;
  const [queryResults] = useContactsSubscription({
    pause,
    variables: {
      currentUserEmail: user?.email,
    },
  });

  useEffect(() => {
    if (queryResults?.data?.new_contact) {
      setContacts(queryResults.data.new_contact);
    }
  }, [queryResults?.data?.new_contact, setContacts]);

  useEffect(() => {
    if (!areGridEventsReady) return;

    requestIdleCallback(() => {
      syncContactsMutation({ isFullSync: true });
    });
  }, [areGridEventsReady, syncContactsMutation]);

  return {
    contacts,
  };
}
