/* 718f914438af3eb24a4df8792ca91385b2073972
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OwnCalendarListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OwnCalendarListQuery = { __typename?: 'query_root', ownCalendarList: Array<{ __typename?: 'MinimalCalendarInfo', id: string, name: string, colorFamily: Types.ColorFamily, emoji?: string | null }> };


export const OwnCalendarListDocument = gql`
    query OwnCalendarList {
  ownCalendarList {
    id
    name
    colorFamily
    emoji
  }
}
    `;

export function useOwnCalendarListQuery(options?: Omit<Urql.UseQueryArgs<OwnCalendarListQueryVariables>, 'query'>) {
  return Urql.useQuery<OwnCalendarListQuery>({ query: OwnCalendarListDocument, ...options });
};