import { hasOnboardedAtom } from 'hooks/preferences/preferencesAtoms';
import { useIsPreferencesReady } from 'hooks/usePreferences';
import { atom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { useRouter } from 'next/router';
import { isOnboardingFlagSet } from 'utils/router';

export const useOnboarding = () => {
  const router = useRouter();
  const hasOnboarded = useAtomValue(hasOnboardedAtom);
  const isPreferencesReady = useIsPreferencesReady();

  return {
    hasOnboarded: !!hasOnboarded,
    shouldOnboard:
      isOnboardingFlagSet(router) || (!hasOnboarded && isPreferencesReady),
  };
};

export enum OnboardingState {
  NOT_ONBOARDING,
  USER_SETTINGS,
  FINISH_STEP,
}

export const onboardingStepAtom = atom(OnboardingState.NOT_ONBOARDING);
