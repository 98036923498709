/* 1c181ca61e9bf0dc0e970691c8ee9efb50636e62
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ContactsSubscriptionVariables = Types.Exact<{
  currentUserEmail?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ContactsSubscription = { __typename?: 'subscription_root', new_contact: Array<{ __typename?: 'new_contact', id: any, emailAddress: string, labels: any, lastInteractedAt: any, avatar?: string | null, displayName?: string | null, favoriteAt?: any | null, notes?: string | null }> };

export type ContactFragmentFragment = { __typename?: 'new_contact', id: any, emailAddress: string, labels: any, lastInteractedAt: any, avatar?: string | null, displayName?: string | null, favoriteAt?: any | null, notes?: string | null };

export type ContactsByFavoriteSubscriptionVariables = Types.Exact<{
  domainEndsWith?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ContactsByFavoriteSubscription = { __typename?: 'subscription_root', new_contact: Array<{ __typename?: 'new_contact', id: any, emailAddress: string, labels: any, lastInteractedAt: any, avatar?: string | null, displayName?: string | null, favoriteAt?: any | null, notes?: string | null, status?: { __typename?: 'userStatus', emoji?: string | null, status: string, endAt?: any | null, integrationAmieEndAt?: any | null, integrationAmieEventId?: string | null, integrationAmieStartAt?: any | null, integrationAmieTitle?: string | null, message?: string | null, startAt?: any | null, userEmail: string, userId?: string | null } | null }> };

export type StatusFragmentFragment = { __typename?: 'userStatus', emoji?: string | null, status: string, endAt?: any | null, integrationAmieEndAt?: any | null, integrationAmieEventId?: string | null, integrationAmieStartAt?: any | null, integrationAmieTitle?: string | null, message?: string | null, startAt?: any | null, userEmail: string, userId?: string | null };

export const ContactFragmentFragmentDoc = gql`
    fragment ContactFragment on new_contact {
  id
  emailAddress
  labels
  lastInteractedAt
  avatar
  displayName
  favoriteAt
  notes
}
    `;
export const StatusFragmentFragmentDoc = gql`
    fragment StatusFragment on userStatus {
  emoji
  status
  endAt
  integrationAmieEndAt
  integrationAmieEventId
  integrationAmieStartAt
  integrationAmieTitle
  message
  startAt
  userEmail
  userId
}
    `;
export const ContactsDocument = gql`
    subscription Contacts($currentUserEmail: String) {
  new_contact(
    order_by: {lastInteractedAt: desc_nulls_last, favoriteAt: desc_nulls_last}
    where: {_not: {emailAddress: {_eq: $currentUserEmail}}}
  ) {
    ...ContactFragment
  }
}
    ${ContactFragmentFragmentDoc}`;

export function useContactsSubscription<TData = ContactsSubscription>(options: Omit<Urql.UseSubscriptionArgs<ContactsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<ContactsSubscription, TData>) {
  return Urql.useSubscription<ContactsSubscription, TData, ContactsSubscriptionVariables>({ query: ContactsDocument, ...options }, handler);
};
export const ContactsByFavoriteDocument = gql`
    subscription ContactsByFavorite($domainEndsWith: String) {
  new_contact(
    order_by: {favoriteAt: asc_nulls_last}
    where: {_and: [{_not: {favoriteAt: {_is_null: true}}}, {emailAddress: {_like: $domainEndsWith}}]}
  ) {
    ...ContactFragment
    status {
      ...StatusFragment
    }
  }
}
    ${ContactFragmentFragmentDoc}
${StatusFragmentFragmentDoc}`;

export function useContactsByFavoriteSubscription<TData = ContactsByFavoriteSubscription>(options: Omit<Urql.UseSubscriptionArgs<ContactsByFavoriteSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<ContactsByFavoriteSubscription, TData>) {
  return Urql.useSubscription<ContactsByFavoriteSubscription, TData, ContactsByFavoriteSubscriptionVariables>({ query: ContactsByFavoriteDocument, ...options }, handler);
};