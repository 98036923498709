import { perfEnd, perfStart } from 'contexts/performance';
import { useUpdateTodosMutation } from 'graphql/mutations/UpdateTodos.graphql';
import { useTodosQuery } from 'graphql/queries/todos.graphql';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import { useAtomValue, useResetAtom, useUpdateAtom } from 'jotai/utils';
import { useEffect, useMemo } from 'react';
import { cacheTodosAtom, showArchivedListsAtom, todosAtom } from './todosAtoms';
import { QueryType } from './types';
import { todosPoolAtom } from './useUpdateTodos';

export function SyncTodos() {
  const setCacheTodos = useUpdateAtom(cacheTodosAtom);
  const setTodos = useUpdateAtom(todosAtom);
  const reset = useResetAtom(todosAtom);
  const resetUpdatePool = useResetAtom(todosPoolAtom);
  const showArchivedLists = useAtomValue(showArchivedListsAtom);
  const [, update] = useUpdateTodosMutation();

  const queryOptions: QueryType = useMemo(
    () => ({
      requestPolicy: 'network-only' as const,
      variables: { hideArchived: !showArchivedLists }, // refetch data when changing between archived and active list view
    }),
    [showArchivedLists]
  );
  const [queryResults] = useTodosQuery(queryOptions);

  useEffect(() => {
    perfStart('loading-todos');
  }, []);

  const pool = useAtomValue(todosPoolAtom);
  const debouncedPool = useDebouncedValue(pool, 500);

  useEffect(() => {
    if (debouncedPool.categories.length > 0 || debouncedPool.todos.length > 0) {
      update({ objects: debouncedPool });
      resetUpdatePool();
    }
  }, [debouncedPool, resetUpdatePool, update]);

  useEffect(() => {
    if (queryResults?.data && !queryResults.stale) {
      perfEnd('loading-todos');
      if (showArchivedLists) {
        // Only set in-memory data without writing into cache
        setTodos(queryResults.data);
      } else {
        setCacheTodos(queryResults.data);
        // Discard optimistic changes
        reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResults.data]);

  return null;
}
