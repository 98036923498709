/* fcf850ef9e881bc7a35b59d1515d07b91980be74
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CalendarsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CalendarsQuery = { __typename?: 'query_root', calendar: Array<{ __typename?: 'NewCalendar', name: string, id: string, isPrimary: boolean, colorFamily?: Types.ColorFamily | null, displayOptions: { __typename?: 'DisplayOptions', backgroundColor: string, foregroundColor: string } }> };


export const CalendarsDocument = gql`
    query Calendars {
  calendar {
    name
    id
    isPrimary
    colorFamily
    displayOptions {
      backgroundColor
      foregroundColor
    }
  }
}
    `;

export function useCalendarsQuery(options?: Omit<Urql.UseQueryArgs<CalendarsQueryVariables>, 'query'>) {
  return Urql.useQuery<CalendarsQuery>({ query: CalendarsDocument, ...options });
};