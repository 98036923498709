/* e5e1499395dbfcc6579a3fff7d6361d52c04f5d8
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RetrieveSharedCalendarLinkQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  fromDate: Types.Scalars['DateTime'];
  toDate: Types.Scalars['DateTime'];
}>;


export type RetrieveSharedCalendarLinkQuery = { __typename?: 'query_root', retrieveSharedCalendarLink: { __typename?: 'RetrieveSharedCalendarLinkOutput', alias?: string | null, bufferDurationMinutes: number, calendarId: string, coverImage?: string | null, description?: string | null, eventMaxDurationMinutes: number, eventMinDurationMinutes: number, id: string, title: string, ownerName: string, ownerAvatar: string, location?: string | null, expiresAt: string, bookedCount: number, slots: Array<{ __typename?: 'CalendarSlot', recurrenceRules?: Array<string> | null, endAt: string, startAt: string }> } };

export type CalendarLinksForUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CalendarLinksForUserQuery = { __typename?: 'query_root', shareCalendarLink: Array<{ __typename?: 'shareCalendarLink', alias?: string | null, id: any, type: Types.ShareCalendarLinkTypeEnum_Enum, expiresAt?: any | null, bookedCount: number, createdAt: any, updatedAt: any, payload?: any | null }> };


export const RetrieveSharedCalendarLinkDocument = gql`
    query RetrieveSharedCalendarLink($id: ID!, $fromDate: DateTime!, $toDate: DateTime!) {
  retrieveSharedCalendarLink(
    objects: {id: $id, fromDate: $fromDate, toDate: $toDate}
  ) {
    alias
    bufferDurationMinutes
    calendarId
    coverImage
    description
    eventMaxDurationMinutes
    eventMinDurationMinutes
    id
    title
    ownerName
    ownerAvatar
    location
    expiresAt
    bookedCount
    slots {
      recurrenceRules
      endAt
      startAt
    }
  }
}
    `;

export function useRetrieveSharedCalendarLinkQuery(options: Omit<Urql.UseQueryArgs<RetrieveSharedCalendarLinkQueryVariables>, 'query'>) {
  return Urql.useQuery<RetrieveSharedCalendarLinkQuery>({ query: RetrieveSharedCalendarLinkDocument, ...options });
};
export const CalendarLinksForUserDocument = gql`
    query CalendarLinksForUser {
  shareCalendarLink {
    alias
    id
    type
    expiresAt
    bookedCount
    createdAt
    updatedAt
    payload
  }
}
    `;

export function useCalendarLinksForUserQuery(options?: Omit<Urql.UseQueryArgs<CalendarLinksForUserQueryVariables>, 'query'>) {
  return Urql.useQuery<CalendarLinksForUserQuery>({ query: CalendarLinksForUserDocument, ...options });
};