/* 52178943fc4845afe9b44bc8d03176bfdd69eefb
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SyncContactsMutationVariables = Types.Exact<{
  isFullSync?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type SyncContactsMutation = { __typename?: 'mutation_root', syncContacts: { __typename?: 'SyncContactsOutput', message: string, success: boolean } };


export const SyncContactsDocument = gql`
    mutation SyncContacts($isFullSync: Boolean) {
  syncContacts(objects: {isFullSync: $isFullSync}) {
    message
    success
  }
}
    `;

export function useSyncContactsMutation() {
  return Urql.useMutation<SyncContactsMutation, SyncContactsMutationVariables>(SyncContactsDocument);
};