import { ColorFamily } from '@graphql-types@';
import hotkeys, { HotkeysEvent } from 'hotkeys-js';
import {
  DependencyList,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { HotkeyScope } from 'types/hotkeys';
import { KEYUP_CODE_COLOR_MAP } from 'utils/eventColors';

hotkeys.filter = () => true;

export type UseHotkeyCallback = (
  event: KeyboardEvent,
  handler: HotkeysEvent & { splitKey?: string }
) => void;
interface HotkeyOptions {
  // Custom hook options
  enabled?: boolean;
  enabledWithinInput?: boolean;
  enabledWithinContentEditable?: boolean;
  scope?: HotkeyScope;
  filter?: typeof hotkeys.filter;
  filterPreventDefault?: boolean;
  // Native hotkeys.js options that are passed directly to the handler
  element?: HTMLElement | null;
  keyup?: boolean | null;
  keydown?: boolean | null;
  splitKey?: string;
}

const defaultHotkeyOptions = {
  enabledWithinInput: false,
  enabledWithinContentEditable: false,
  filterPreventDefault: true,
};

const isTriggeredByInput = (event: KeyboardEvent) => {
  const targetTagName = event.target && (event.target as HTMLElement).tagName;
  if (!targetTagName) return false;

  return ['INPUT', 'TEXTAREA', 'SELECT'].includes(targetTagName);
};

const isTriggeredByContentEditable = (event: KeyboardEvent) => {
  const isContentEditable =
    event.target && (event.target as HTMLElement).isContentEditable;

  return isContentEditable;
};

export default function useHotkey(
  keyCombo: string,
  scopeOrOptions: HotkeyScope | HotkeyOptions,
  callback: UseHotkeyCallback,
  deps: DependencyList = []
): void {
  const options = useMemo(() => {
    if (typeof scopeOrOptions !== 'object')
      return { ...defaultHotkeyOptions, scope: scopeOrOptions };

    const scope = scopeOrOptions.scope || 'global';
    return { ...defaultHotkeyOptions, ...scopeOrOptions, scope };
  }, [scopeOrOptions]);

  const callbackWithFilter: UseHotkeyCallback = useCallback(
    (event: KeyboardEvent, handler: HotkeysEvent) => {
      // Run through a custom filter if passed to hook
      if (options.filter && options.filter(event)) {
        return !options.filterPreventDefault;
      }

      // Prevent default if event triggered within input
      if (isTriggeredByInput(event) && !options.enabledWithinInput) {
        return true;
      }

      // Prevent default if event triggered within content editable
      if (
        isTriggeredByContentEditable(event) &&
        !options.enabledWithinContentEditable
      ) {
        return true;
      }

      callback(event, handler);
    },
    [callback, options]
  );

  useEffect(() => {
    if (options.enabled === false) {
      return () => hotkeys.unbind(keyCombo, options.scope, callbackWithFilter);
    }

    hotkeys(keyCombo, options, callbackWithFilter);

    return () => hotkeys.unbind(keyCombo, options.scope, callbackWithFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackWithFilter, keyCombo, options, ...deps]);
}

export function useColorHotkey(
  callback: (color: ColorFamily) => void,
  scopeOrOptions: HotkeyScope | HotkeyOptions
): void {
  useHotkey(
    '1,2,3,4,5,6,7,8',
    scopeOrOptions,
    (e) => {
      e.preventDefault();
      callback(KEYUP_CODE_COLOR_MAP[e.key]);
    },
    []
  );
}

/**
 * Hotkeys doesn't detect certain inputs such as cmd and alt, so we listen to the document.
 */
function useDocumentKeyListener(
  isKeyPressed: (e: KeyboardEvent | MouseEvent | null) => boolean
) {
  const [keyDown, setKeyDown] = useState(isKeyPressed(null));

  const onKeyStateChange = useCallback(
    (e) => setKeyDown(isKeyPressed(e)),
    [isKeyPressed]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyStateChange);
    document.addEventListener('keyup', onKeyStateChange);
    document.addEventListener('focus', onKeyStateChange);
    document.addEventListener('blur', onKeyStateChange);
    document.addEventListener('mousemove', onKeyStateChange);

    return () => {
      document.removeEventListener('keydown', onKeyStateChange);
      document.removeEventListener('keyup', onKeyStateChange);
      document.removeEventListener('focus', onKeyStateChange);
      document.removeEventListener('blur', onKeyStateChange);
      document.removeEventListener('mousemove', onKeyStateChange);
    };
  }, [onKeyStateChange]);

  return keyDown;
}

export function useAltKey(): boolean {
  return useDocumentKeyListener((e: KeyboardEvent | MouseEvent | null) =>
    e ? e.altKey : !!hotkeys.alt
  );
}

export function useCmdKey(): boolean {
  // control(^) key on Mac and Ctrl key on Windows have the same key code
  // so this means we are allowing the use of ^ to support Ctrl
  return useDocumentKeyListener((e: KeyboardEvent | MouseEvent | null) =>
    e ? e.metaKey || e.ctrlKey : !!hotkeys.cmd || !!hotkeys.ctrl
  );
}
