import React, { useEffect, useState } from 'react';
import Alert from '@reach/alert';
import { motion, AnimatePresence } from 'framer-motion';

const OfflineNotification: React.FC = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    function updateOnlineStatus() {
      setIsOnline(navigator.onLine ? true : false);
    }

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  return (
    <AnimatePresence>
      {!isOnline && (
        <motion.div
          className="fixed bottom-0 right-0 text-primary"
          key="offline-notification"
          initial={{ y: 150, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 150, opacity: 0 }}
        >
          <Alert type="polite" className="p-8">
            <p>
              <span role="img" aria-label="Caution emoji">
                ⚠️
              </span>{' '}
              Offline{' '}
              <span role="img" aria-label="Caution emoji">
                ⚠️
              </span>
            </p>
            <p className="text-sm leading-normal">
              Changes will be synced when online.
            </p>
          </Alert>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OfflineNotification;
