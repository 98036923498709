import { useUpdateProfileByPkMutation } from 'graphql/mutations/UpdateProfileByPk.graphql';
import {
  ProfileViewByEmailQueryVariables,
  useProfileViewByEmailQuery,
} from 'graphql/queries/profile.graphql';
import { atom } from 'jotai';
import {
  atomFamily,
  useAtomCallback,
  useAtomValue,
  useUpdateAtom,
} from 'jotai/utils';
import React from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { TProfileView } from 'types/profile';
import type { UseQueryArgs } from 'urql';

type QueryType = Omit<UseQueryArgs<ProfileViewByEmailQueryVariables>, 'query'>;

interface Props {
  email: string;
}

export const isProfileReadyAtom = atom(false);

export function useIsProfileReady(): boolean {
  return useAtomValue(isProfileReadyAtom);
}

export const profileFamily = atomFamily(() => atom<TProfileView | null>(null));

export default function useProfile(
  email: string | null | undefined
): TProfileView | null {
  return useAtomValue(profileFamily(email));
}

export function useProfileId(email: string): TProfileView | null {
  return useAtomValue(profileFamily(email));
}

export function useProfileUpdate(
  email: string
): (profile: NonNullable<Partial<TProfileView>>) => void {
  const [, updateProfileByPk] = useUpdateProfileByPkMutation();

  const updateProfile = useAtomCallback(
    useCallback(
      async (get, set, update: NonNullable<Partial<TProfileView>>) => {
        const profile = get(profileFamily(email));
        if (!profile) return;

        set(profileFamily(email), (prevValues) => {
          if (!prevValues) return null;
          return {
            ...prevValues,
            ...update,
          };
        });

        await updateProfileByPk({
          id: profile.id,
          _set: update,
        });
      },
      [email, updateProfileByPk]
    )
  );

  return updateProfile;
}

export const SyncProfile = React.memo(function SyncProfile({
  email,
}: Props): null {
  const setIsReady = useUpdateAtom(isProfileReadyAtom);
  const updateProfile = useUpdateAtom(profileFamily(email));
  const guard = useRef(false);

  const query = useMemo<QueryType>(
    () =>
      email
        ? {
            variables: { email },
            context: {
              suspense: true,
              requestPolicy: 'cache-and-network' as const,
            },
          }
        : { pause: true },
    [email]
  );

  const [queryResults] = useProfileViewByEmailQuery(query);

  useEffect(() => {
    guard.current = true;
    return () => {
      guard.current = false;
    };
  }, []);

  useEffect(() => {
    if (!queryResults?.data?.profileView) return;

    // Don't allow setState if unmounted.
    if (guard.current) {
      setIsReady(true);
      updateProfile(queryResults.data.profileView);
    }
  }, [queryResults.data?.profileView, setIsReady, updateProfile]);

  return null;
});
