import { CombinedError, Operation } from 'urql';
import { useErrorStore } from '../../store/error';

export default {
  onError(error: CombinedError, _operation: Operation): void {
    console.error(error);

    useErrorStore.setState({ error });
  },
};
