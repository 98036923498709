import { atomWithStorage } from 'jotai/utils';
import { User } from 'types/user';
import type { UserQuery } from 'graphql/queries/user.graphql';

/**
 * User from Firebase SDK
 */
export const userAtom = atomWithStorage<User | null>('userAtom', null);

/**
 * User fetched from DB
 */
export const fullUserAtom = atomWithStorage<UserQuery['new_user'][0] | null>(
  'fullUserAtom',
  null
);
