import { CalendarSlot, CalendarSlotInput } from '@graphql-types@';
import { DateTime } from 'luxon';
import { uuid } from 'utils/toolbox';
import { CalendarSlotDate } from '../booking/atoms';

export function serverSlotsToFrontendSlots(
  slots: CalendarSlot[],
  calendarLinkId: string
): CalendarSlotDate[] {
  return slots.map((slot) => ({
    id: uuid(),
    recurrenceRules: slot.recurrenceRules || [],
    startAt: DateTime.fromISO(slot.startAt),
    endAt: DateTime.fromISO(slot.endAt),
    calendarLinkId,
  }));
}

export function frontendSlotsToServerSlots(
  slots: CalendarSlotDate[]
): CalendarSlotInput[] {
  return slots.map((slot) => ({
    id: slot.id,
    recurrenceRules: slot.recurrenceRules || [],
    startAt: slot.startAt.toISO(),
    endAt: slot.endAt.toISO(),
  }));
}
