import { getRefreshedFirebaseIdToken } from 'hooks/auth/useAuth';
import { parseCookies } from 'nookies';
import { COOKIE_NAME_FIREBASE_ID_TOKEN } from 'utils/constants';
import { logout } from 'utils/logout';
import { firebase } from 'utils/services/firebaseShared';

import { makeOperation } from '@urql/core';
import { authExchange } from '@urql/exchange-auth';
import pRetry from 'p-retry';
import { isTokenValid } from 'hooks/auth/authAtoms';

export const getTokenFromFirebaseOrCookie = async (): Promise<string> => {
  const cookies = parseCookies();
  const fromLib = await firebase.auth().currentUser?.getIdToken();
  const fromCookie = cookies[COOKIE_NAME_FIREBASE_ID_TOKEN];
  const firebaseIdToken = fromLib || fromCookie;

  if (!firebaseIdToken) {
    throw new Error('No token');
  }

  return firebaseIdToken;
};

const fetchAuthExchange = authExchange<{
  firebaseIdToken: string | null;
} | null>({
  addAuthToOperation: ({ authState, operation }) => {
    // the token isn't in the auth state, return the operation without changes
    if (!authState || !authState.firebaseIdToken) {
      return operation;
    }

    // fetchOptions can be a function (See Client API) but you can simplify this based on usage
    const fetchOptions =
      typeof operation.context.fetchOptions === 'function'
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {};

    return makeOperation(operation.kind, operation, {
      ...operation.context,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          Authorization: `Bearer ${authState.firebaseIdToken}`,
          'x-amie-client': 'web',
        },
      },
    });
  },
  willAuthError: ({ authState }) => {
    if (!authState) return true;
    if (!isTokenValid(authState.firebaseIdToken)) return true;
    // e.g. check for expiration, existence of auth etc
    return false;
  },
  didAuthError: ({ error }) => {
    return error.message.includes('Authorization failed');
  },
  getAuth: async ({ authState }) => {
    if (typeof window === 'undefined') return { firebaseIdToken: null };

    // for initial launch, fetch the auth state from storage (local storage, async storage etc)
    if (!authState) {
      const firebaseIdToken = await pRetry(getTokenFromFirebaseOrCookie, {
        retries: 5,
      });

      // valid?
      if (isTokenValid(firebaseIdToken)) {
        return { firebaseIdToken };
      }

      const refreshed = await getRefreshedFirebaseIdToken();
      if (refreshed) {
        return { firebaseIdToken: refreshed };
      }

      return null;
    }

    /**
     * the following code gets executed when an auth error has occurred
     * we should refresh the token if possible and return a new auth state
     * If refresh fails, we should log out
     **/
    const firebaseIdToken = await getRefreshedFirebaseIdToken();
    if (firebaseIdToken) {
      return { firebaseIdToken };
    }

    logout();

    return null;
  },
});

export default fetchAuthExchange;
