/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { urqlClientWithoutSubscriptions } from 'graphql/client';
import {
  CreateEventMutationVariables,
  CreateEventMutation,
  CreateEventDocument,
} from 'graphql/mutations/CreateEvent.graphql';
import {
  DeleteEventMutationVariables,
  DeleteEventMutation,
  DeleteEventDocument,
} from 'graphql/mutations/DeleteEvent.graphql';
import {
  UpdateEventMutationVariables,
  UpdateEventMutation,
  UpdateEventDocument,
} from 'graphql/mutations/UpdateEvent.graphql';
import { EventsQuery, EventsDocument } from 'graphql/queries/events.graphql';
import {
  SingleEventDocument,
  SingleEventQuery,
  SingleEventQueryVariables,
} from 'graphql/queries/singleEvent.graphql';
import { DateTime } from 'luxon';
import { EventName } from 'types/analytics';
import { trackEvent } from 'utils/analytics';

interface ServerEventsQueryParams {
  startAt: DateTime;
  endAt: DateTime;
  calendarIds: string[];
  isUserCalendar: boolean;
}

export async function serverEventsQuery({
  startAt,
  endAt,
  calendarIds,
}: ServerEventsQueryParams) {
  return urqlClientWithoutSubscriptions
    .query<EventsQuery>(
      EventsDocument,
      {
        calendarIds,
        startAt: startAt?.toISO(),
        endAt: endAt?.toISO(),
      },
      { requestPolicy: 'network-only' }
    )
    .toPromise()
    .then((response) => {
      return (response.data?.event?.events || []).map(
        mapCompoundIdServerToClient
      );
    })
    .catch((error) => {
      console.error(error);
      return undefined;
    });
}

export async function updateEventMutation(
  payload: UpdateEventMutationVariables
) {
  trackEvent(EventName.UpdatedEvent);
  return urqlClientWithoutSubscriptions
    .mutation<UpdateEventMutation>(
      UpdateEventDocument,
      mapCompoundIdClientToServer(payload),
      {
        requestPolicy: 'cache-and-network',
      }
    )
    .toPromise()
    .then((response) => {
      if (!response.data?.updateEvent?.event) {
        return null;
      }
      return mapCompoundIdServerToClient(response.data.updateEvent.event);
    });
}

export async function deleteEventMutation(
  payload: DeleteEventMutationVariables
) {
  trackEvent(EventName.DeletedEvent);
  return urqlClientWithoutSubscriptions
    .mutation<DeleteEventMutation>(
      DeleteEventDocument,
      mapCompoundIdClientToServer(payload),
      {
        requestPolicy: 'cache-and-network',
      }
    )
    .toPromise();
}

export async function createEventMutation(
  payload: CreateEventMutationVariables
) {
  trackEvent(EventName.CreatedEvent);

  const payloadCopy = {
    ...payload,
    id: compoundEventIdToGoogleEventId(payload.id),
  };

  return urqlClientWithoutSubscriptions
    .mutation<CreateEventMutation>(CreateEventDocument, payloadCopy, {
      requestPolicy: 'cache-and-network',
    })
    .toPromise()
    .then((response) => {
      if (!response.data?.createEvent?.event) {
        return null;
      }
      return mapCompoundIdServerToClient(response.data.createEvent.event);
    });
}

export function fetchSingleEventQuery(payload: SingleEventQueryVariables) {
  return urqlClientWithoutSubscriptions
    .query<SingleEventQuery>(
      SingleEventDocument,
      mapCompoundIdClientToServer(payload)
    )
    .toPromise()
    .then((response) => {
      if (response.data?.singleEvent) {
        return mapCompoundIdServerToClient(response.data.singleEvent);
      }
      return null;
    })
    .catch(() => {
      return null;
    });
}

/**
 * ⚠️ USE SPARINGLY.
 *
 * Takes our compound event id (event id + calendar id) and returns just the
 * Google event id which is needed by the API.
 */
function compoundEventIdToGoogleEventId(id: string) {
  const [eventId] = id.split(':');
  return eventId;
}

function mapCompoundIdClientToServer<
  EventType extends {
    eventId: string;
    recurringEventId?: string | null;
  }
>(event: EventType): EventType {
  return {
    ...event,
    eventId: compoundEventIdToGoogleEventId(event.eventId),
    recurringEventId: event.recurringEventId
      ? compoundEventIdToGoogleEventId(event.recurringEventId)
      : undefined,
  };
}

function mapCompoundIdServerToClient<
  EventType extends {
    recurringEventId?: string | null;
    calendarId: string;
    compoundId: string;
  }
>(event: EventType): EventType {
  return {
    ...event,
    id: event.compoundId,
    recurringEventId: event.recurringEventId
      ? `${event.recurringEventId}:${event.calendarId}`
      : undefined,
  };
}
