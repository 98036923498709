import { DateTime } from 'luxon';
import { isSameDay } from 'utils/time';
import { CalendarSlotDate } from '../booking/atoms';

export function generateTimeSlots({
  day,
  slots,
  durationMinutes,
  bufferDurationMinutes,
}: {
  day: DateTime;
  slots: CalendarSlotDate[];
  durationMinutes: number;
  bufferDurationMinutes: number;
}): CalendarSlotDate[] {
  const timeframeSlots = slots.filter((slot) => isSameDay(day, slot.startAt));
  return timeframeSlots
    .reduce((results, slot) => {
      const splittedSlots = splitSlot(
        slot,
        durationMinutes,
        bufferDurationMinutes
      );
      return [...results, ...splittedSlots];
    }, [] as CalendarSlotDate[])
    .filter(
      (slot) =>
        slot.startAt > DateTime.now().plus({ minutes: bufferDurationMinutes })
    );
}

function splitSlot(
  slot: CalendarSlotDate,
  durationMinutes: number,
  bufferDurationMinutes: number
): CalendarSlotDate[] {
  const slotDuration = slot.endAt.diff(slot.startAt, 'minutes').as('minutes');
  const subSlotDurationWithBuffer = durationMinutes + bufferDurationMinutes;
  const subSlotsCount =
    1 +
    Math.floor((slotDuration - durationMinutes) / subSlotDurationWithBuffer);

  return new Array(subSlotsCount).fill(null).map((_, index) => ({
    ...slot,
    startAt: slot.startAt.plus({ minutes: subSlotDurationWithBuffer * index }),
    endAt: slot.startAt.plus({
      minutes: subSlotDurationWithBuffer * index + durationMinutes,
    }),
  }));
}
