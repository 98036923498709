/**
 * Use human-readable names here
 * https://segment.com/docs/connections/spec/track/#event
 */
export enum EventName {
  CreatedTodo = 'Created Todo',
  DeletedTodo = 'Deleted Todo',
  UpdatedTodo = 'Updated Todo',
  CreatedEvent = 'Created Event',
  DeletedEvent = 'Deleted Event',
  UpdatedEvent = 'Updated Event',
  UpdatedEventRSVP = 'Updated Event RSVP',
  DuplicatedEvent = 'Duplicated Event',
  UpdatedPreference = 'Updated Preference',
  RemovedEventAttendee = 'Removed Event Attendee',
  AddedEventAttendees = 'Added Event Attendees',
  SetEventRecurring = 'Set Event Recurring',
  OpenedVideoConference = 'Opened Video Conference',
  ToggledQuickMenu = 'Toggled Quick Menu',
  CopiedSlotsText = 'Copied Share Slots Text',
  CopiedSlotsLink = 'Copied Share Slots Link',
  CreatedShareLink = 'Created Share Link',
  UpdatedShareLink = 'Updated Share Link',
  DeletedShareLink = 'Deleted Share Link',
}

export type TrackEventFunc = (
  eventName: EventName,
  properties?: TrackProperties
) => void;

export type TrackProperties = Record<string, string | number>;
type Track = (
  event: EventName,
  properties?: TrackProperties,
  options?: Record<string, string>,
  callback?: () => void
) => void;
type IdentifyProperties = { name: string; email: string };
type Identify = (id: string, properties: IdentifyProperties) => void;
type SegmentAnalytics = {
  identify: Identify;
  track: Track;
};

declare global {
  interface Window {
    analyticsUserId?: string;
    analytics?: SegmentAnalytics;
  }
}
