export enum DroppableId {
  SCHEDULE = 'SCHEDULE',
  TODOS = 'TODOS',
}

export enum DraggableType {
  TODO = 'TODO',
  TODO_CATEGORY = 'TODO_CATEGORY',
  TODO_PLACEHOLDER = 'TODO_PLACEHOLDER',
  EVENT = 'EVENT',
  SLOT = 'SLOT',
  UNDONE_EVENT = 'UNDONE_EVENT',
  CONTACT = 'CONTACT',
  NONE = 'NONE',
}
