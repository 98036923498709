import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { CalendarSlotDate } from './booking/atoms';

export type CalendarLinkType = 'one-off' | 'recurring';
export interface CalendarLink {
  id: string;
  alias: string;
  type: CalendarLinkType;
  bookedCount: number;
  createdAt: Date;
  updatedAt: Date;
  bufferDurationMinutes: number;
  calendarId: string;
  description: string;
  location: string;
  title: string;
  status: 'draft' | 'creating' | 'published';
  eventMaxDurationMinutes: number;
  eventMinDurationMinutes: number;
  slots: CalendarSlotDate[];
}

export const calendarLinksFamily = atomFamily(() =>
  atom<CalendarLink | null>(null)
);

export const calendarLinkIdsAtom = atom<string[]>([]);
