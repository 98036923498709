/* c211bcc886630b2c2250439eb2920b757f049e4c
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateUserPreferencesMutationVariables = Types.Exact<{
  userId: Types.Scalars['String'];
  set?: Types.InputMaybe<Types.UserPreferences_Set_Input>;
}>;


export type UpdateUserPreferencesMutation = { __typename?: 'mutation_root', update_userPreferences?: { __typename?: 'userPreferences_mutation_response', returning: Array<{ __typename?: 'userPreferences', id: any, todoPrivacy: boolean, videoProvider?: Types.VideoProvider_Enum | null, theme?: Types.Theme_Enum | null, calendarStartsOn?: Types.CalendarStartsOn_Enum | null, defaultDuration: number, zoomLink?: string | null, dateFormat?: Types.DateFormat_Enum | null, ui24HourClock: boolean, aroundLink?: string | null, timezone?: string | null, uiHideWeekend?: boolean | null, uiVisibleCalendarsId?: string | null, defaultEventColor: Types.ColorFamilyEnum_Enum, hasOnboarded: boolean, workHoursStartTime?: string | null, workHoursEndTime?: string | null, workHoursEnabled: boolean, hideDeclinedEvents?: boolean | null, showDoneTodos: boolean, showArchivedLists: boolean }> } | null };


export const UpdateUserPreferencesDocument = gql`
    mutation UpdateUserPreferences($userId: String!, $set: userPreferences_set_input) {
  update_userPreferences(where: {userId: {_eq: $userId}}, _set: $set) {
    returning {
      id
      todoPrivacy
      videoProvider
      theme
      calendarStartsOn
      defaultDuration
      zoomLink
      dateFormat
      ui24HourClock
      aroundLink
      timezone
      uiHideWeekend
      uiVisibleCalendarsId
      defaultEventColor
      hasOnboarded
      workHoursStartTime
      workHoursEndTime
      workHoursEnabled
      hideDeclinedEvents
      showDoneTodos
      showArchivedLists
    }
  }
}
    `;

export function useUpdateUserPreferencesMutation() {
  return Urql.useMutation<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>(UpdateUserPreferencesDocument);
};