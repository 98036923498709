import React, { Component, ErrorInfo, ReactNode } from 'react';
import { CombinedError } from 'urql';
import { useErrorStore, ErrorStoreState } from '../../store/error';
import GenericError from './GenericError';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: CombinedError | undefined;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: undefined,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: undefined };
  }

  public componentDidCatch(error: CombinedError, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);

    this.setState({ error });
  }

  setError = (state: ErrorStoreState) => {
    if (state.error) {
      this.setState({ error: state.error, hasError: true });
    }
  };

  reset = () => {
    this.setState({ hasError: false, error: undefined });
    useErrorStore.setState({ error: undefined });
  };

  componentDidMount() {
    // Receive errors from non-react render origins (GraphQL requests, etc)
    useErrorStore.subscribe(this.setError);
  }

  componentWillUnmount() {
    useErrorStore.destroy();
  }

  public render() {
    return (
      <React.Fragment>
        {this.props.children}
        {this.state.hasError &&
          this.state.error &&
          process.env.NODE_ENV !== 'production' && (
            <GenericError error={this.state.error} resetError={this.reset} />
          )}
      </React.Fragment>
    );
  }
}

export default ErrorBoundary;
