/* 6affb0b4ce088df445b3dcb784a0390564fa24e6
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTodosMutationVariables = Types.Exact<{
  objects: Types.UpdateTodosInput;
}>;


export type UpdateTodosMutation = { __typename?: 'mutation_root', updateTodos: { __typename?: 'UpdateTodosOutput', categories: Array<{ __typename?: 'TodoCategory', name?: string | null, id?: string | null, updatedAt: any, createdAt?: any | null, lastClientUpdate?: any | null, archivedAt?: any | null, deletedAt?: any | null, colorFamily?: string | null, rank?: string | null, userId?: string | null, expanded?: boolean | null }>, todos: Array<{ __typename?: 'TodoItem', name?: string | null, id?: string | null, createdAt?: any | null, updatedAt: any, lastClientUpdate?: any | null, archivedAt?: any | null, deletedAt?: any | null, categoryId: string, doneAt?: any | null, rank?: string | null }> } };


export const UpdateTodosDocument = gql`
    mutation UpdateTodos($objects: UpdateTodosInput!) {
  updateTodos(objects: $objects) {
    categories {
      name
      id
      updatedAt
      createdAt
      lastClientUpdate
      archivedAt
      deletedAt
      colorFamily
      rank
      userId
      expanded
    }
    todos {
      name
      id
      createdAt
      updatedAt
      lastClientUpdate
      archivedAt
      deletedAt
      categoryId
      doneAt
      rank
    }
  }
}
    `;

export function useUpdateTodosMutation() {
  return Urql.useMutation<UpdateTodosMutation, UpdateTodosMutationVariables>(UpdateTodosDocument);
};