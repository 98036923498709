import { ObservableLike } from '@urql/core/dist/types/exchanges/subscription';
import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange } from '@urql/exchange-graphcache';
import { refocusExchange } from '@urql/exchange-refocus';
import { retryExchange } from '@urql/exchange-retry';
import type { RetryExchangeOptions } from '@urql/exchange-retry/dist/types/retryExchange';
import { parseCookies } from 'nookies';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import {
  createClient,
  dedupExchange,
  errorExchange,
  ExecutionResult,
  fetchExchange,
  subscriptionExchange,
} from 'urql';
import { COOKIE_NAME_FIREBASE_ID_TOKEN } from 'utils/constants';
import { firebase } from 'utils/services/firebaseShared';
import cacheExchangeConfig from './exchanges/cacheExchangeConfig';
import errorExchangeConfig from './exchanges/errorExchangeConfig';
import fetchAuthExchange from './exchanges/fetchAuthExchange';

const retryExchangeOpts: RetryExchangeOptions = {
  initialDelayMs: 1000,
  maxDelayMs: 5000,
  randomDelay: true,
  maxNumberAttempts: 3,
  retryIf: (err) => {
    return (
      Boolean(err && err.networkError) ||
      Boolean(err && err.message.includes('database query error')) ||
      Boolean(err && err.message.includes('Authorization failed'))
    );
  },
};

const getWebsocket = () => {
  if (!process.env.STORYBOOK_MODE && typeof window === 'undefined') {
    const ws = require('ws');

    return ws;
  }

  // Browser has WebSockets, so no need to return...
};

const createSubscriptionClient = () =>
  new SubscriptionClient(
    process.env.NEXT_PUBLIC_GRAPHQL_WEBSOCKET_ENDPOINT || '',
    {
      reconnect: true,
      lazy: true,
      connectionParams: async () => {
        const cookies = parseCookies();
        const firebaseToken = await firebase.auth().currentUser?.getIdToken();
        const cookiefallback = cookies[COOKIE_NAME_FIREBASE_ID_TOKEN];
        const token = firebaseToken || cookiefallback;

        return {
          headers: {
            Authorization: token ? `Bearer ${token}` : undefined,
            'x-amie-client': 'web',
          },
        };
      },
    },
    getWebsocket()
  );

let subscriptionsClient = createSubscriptionClient();

const minimalUrqlClient = (token?: string) => {
  const headers: {
    'x-amie-client': string;
    Authorization?: string;
  } = {
    'x-amie-client': 'web',
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return createClient({
    url: process.env.NEXT_PUBLIC_GRAPHQL_HTTP_ENDPOINT_V2 || '',
    exchanges: [fetchExchange],
    fetchOptions: {
      headers,
    },
  });
};

const isCypress = parseCookies()['CYPRESS'] === 'true';

const noAuthExchanges = [
  dedupExchange,
  refocusExchange(),
  ...(isCypress === false ? [cacheExchange(cacheExchangeConfig)] : []),
  retryExchange(retryExchangeOpts),
  errorExchange(errorExchangeConfig),
  fetchExchange,
];

const baseExchanges = [
  dedupExchange,
  refocusExchange(),
  ...(isCypress === false ? [cacheExchange(cacheExchangeConfig)] : []),
  retryExchange(retryExchangeOpts),
  errorExchange(errorExchangeConfig),
  fetchAuthExchange,
  fetchExchange,
];

// Without subscriptions. Called from Jotai stuff.
const urqlClientWithoutSubscriptions = createClient({
  url: process.env.NEXT_PUBLIC_GRAPHQL_HTTP_ENDPOINT_V2 || '',
  exchanges: baseExchanges,
});

const exchangesWithSubscriptions = [
  ...baseExchanges,
  subscriptionExchange({
    forwardSubscription(operation) {
      return subscriptionsClient.request(
        operation
      ) as ObservableLike<ExecutionResult>;
    },
  }),
  ...(process.env.NODE_ENV !== 'production' ? [devtoolsExchange] : []),
];

const createUrqlClientForHooks = () => {
  subscriptionsClient = createSubscriptionClient();
  return createClient({
    url: process.env.NEXT_PUBLIC_GRAPHQL_HTTP_ENDPOINT_V2 || '',
    exchanges: exchangesWithSubscriptions,
  });
};
const urqlClientNoAuth = createClient({
  url: process.env.NEXT_PUBLIC_GRAPHQL_HTTP_ENDPOINT_V2 || '',
  exchanges: noAuthExchanges,
});

const urqlClientSuspense = createClient({
  url: process.env.NEXT_PUBLIC_GRAPHQL_HTTP_ENDPOINT_V2 || '',
  suspense: true,
  exchanges: exchangesWithSubscriptions,
});

export {
  minimalUrqlClient,
  urqlClientWithoutSubscriptions,
  urqlClientNoAuth,
  // Includes subscriptions (websockets)
  createUrqlClientForHooks,
  urqlClientSuspense,
};
