/* aa4503622ecdb592e801a65fb0fb8f0bb6f90928
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProfileQueryVariables = Types.Exact<{
  profileId: Types.Scalars['uuid'];
}>;


export type ProfileQuery = { __typename?: 'query_root', profile_by_pk?: { __typename?: 'profile', id: any, email: string, userId?: string | null, handle?: string | null, firstName?: string | null, lastName?: string | null, twitter?: string | null, phone?: string | null, verifiedAt?: any | null, lastLocation?: string | null } | null };

export type ProfileFragmentFragment = { __typename?: 'profile', id: any, email: string, userId?: string | null, handle?: string | null, firstName?: string | null, lastName?: string | null, twitter?: string | null, phone?: string | null, verifiedAt?: any | null, lastLocation?: string | null };

export type ProfileBirthdayDataQueryVariables = Types.Exact<{
  profileId: Types.Scalars['uuid'];
  contactId: Types.Scalars['uuid'];
}>;


export type ProfileBirthdayDataQuery = { __typename?: 'query_root', profile?: { __typename?: 'profile', id: any, email: string, birthday?: any | null } | null, contact?: { __typename?: 'new_contact', id: any, avatar?: string | null, birthday?: any | null, displayName?: string | null } | null };

export type ProfileViewByEmailQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type ProfileViewByEmailQuery = { __typename?: 'query_root', profileView?: { __typename?: 'ProfileViewOutput', id: string, email: string, isOwnProfile: boolean, userId?: string | null, handle?: string | null, firstName?: string | null, lastName?: string | null, birthday?: any | null, favoriteSince?: any | null, twitter?: string | null, photoUrl?: string | null, phone?: string | null, verifiedAt?: any | null, lastLocation?: string | null, contactId: string } | null };

export type ProfileMeetInfoQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type ProfileMeetInfoQuery = { __typename?: 'query_root', profileMeetInfo?: { __typename?: 'ProfileMeetInfoOutput', numberOfTimesMeet: number, lastSeen?: any | null, lastSeenSource?: string | null } | null };

export type FetchBestiesQueryVariables = Types.Exact<{
  domainEndsWith?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchBestiesQuery = { __typename?: 'query_root', bestie: Array<{ __typename?: 'Bestie', id?: string | null, email: string, displayName?: string | null, eventsAttended: number }> };

export const ProfileFragmentFragmentDoc = gql`
    fragment ProfileFragment on profile {
  id
  email
  userId
  handle
  firstName
  lastName
  twitter
  phone
  verifiedAt
  lastLocation
}
    `;
export const ProfileDocument = gql`
    query Profile($profileId: uuid!) {
  profile_by_pk(id: $profileId) {
    ...ProfileFragment
  }
}
    ${ProfileFragmentFragmentDoc}`;

export function useProfileQuery(options: Omit<Urql.UseQueryArgs<ProfileQueryVariables>, 'query'>) {
  return Urql.useQuery<ProfileQuery>({ query: ProfileDocument, ...options });
};
export const ProfileBirthdayDataDocument = gql`
    query ProfileBirthdayData($profileId: uuid!, $contactId: uuid!) {
  profile: profile_by_pk(id: $profileId) {
    id
    email
    birthday
  }
  contact: new_contact_by_pk(id: $contactId) {
    id
    avatar
    birthday
    displayName
  }
}
    `;

export function useProfileBirthdayDataQuery(options: Omit<Urql.UseQueryArgs<ProfileBirthdayDataQueryVariables>, 'query'>) {
  return Urql.useQuery<ProfileBirthdayDataQuery>({ query: ProfileBirthdayDataDocument, ...options });
};
export const ProfileViewByEmailDocument = gql`
    query ProfileViewByEmail($email: String!) {
  profileView(email: $email) {
    id
    email
    isOwnProfile
    userId
    handle
    firstName
    lastName
    birthday
    favoriteSince
    twitter
    photoUrl
    phone
    verifiedAt
    lastLocation
    contactId
  }
}
    `;

export function useProfileViewByEmailQuery(options: Omit<Urql.UseQueryArgs<ProfileViewByEmailQueryVariables>, 'query'>) {
  return Urql.useQuery<ProfileViewByEmailQuery>({ query: ProfileViewByEmailDocument, ...options });
};
export const ProfileMeetInfoDocument = gql`
    query ProfileMeetInfo($email: String!) {
  profileMeetInfo(objects: {email: $email}) {
    numberOfTimesMeet
    lastSeen
    lastSeenSource
  }
}
    `;

export function useProfileMeetInfoQuery(options: Omit<Urql.UseQueryArgs<ProfileMeetInfoQueryVariables>, 'query'>) {
  return Urql.useQuery<ProfileMeetInfoQuery>({ query: ProfileMeetInfoDocument, ...options });
};
export const FetchBestiesDocument = gql`
    query FetchBesties($domainEndsWith: String) {
  bestie(domainEndsWith: $domainEndsWith) {
    id
    email
    displayName
    eventsAttended
  }
}
    `;

export function useFetchBestiesQuery(options?: Omit<Urql.UseQueryArgs<FetchBestiesQueryVariables>, 'query'>) {
  return Urql.useQuery<FetchBestiesQuery>({ query: FetchBestiesDocument, ...options });
};