/* 7b1248ccd206c66f3ed132a9acae7f32af241d25
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'query_root', new_user: Array<{ __typename?: 'new_user', id: string, photo_url?: string | null, display_name: string, email: string, spotifyRefreshToken?: string | null, zoomRefreshToken?: string | null, status?: { __typename?: 'userStatus', emoji?: string | null, status: string, endAt?: any | null, integrationAmieEndAt?: any | null, integrationAmieEventId?: string | null, integrationAmieStartAt?: any | null, integrationAmieTitle?: string | null, message?: string | null, startAt?: any | null, userEmail: string, userId?: string | null } | null, preferences?: { __typename?: 'userPreferences', id: any, todoPrivacy: boolean, showDoneTodos: boolean, showArchivedLists: boolean, videoProvider?: Types.VideoProvider_Enum | null, theme?: Types.Theme_Enum | null, calendarStartsOn?: Types.CalendarStartsOn_Enum | null, defaultDuration: number, zoomLink?: string | null, dateFormat?: Types.DateFormat_Enum | null, ui24HourClock: boolean, aroundLink?: string | null, timezone?: string | null, uiHideWeekend?: boolean | null, uiVisibleCalendarsId?: string | null, defaultEventColor: Types.ColorFamilyEnum_Enum, hasOnboarded: boolean, workHoursStartTime?: string | null, workHoursEndTime?: string | null, workHoursEnabled: boolean, hideDeclinedEvents?: boolean | null } | null }> };

export type StatusFragmentFragment = { __typename?: 'userStatus', emoji?: string | null, status: string, endAt?: any | null, integrationAmieEndAt?: any | null, integrationAmieEventId?: string | null, integrationAmieStartAt?: any | null, integrationAmieTitle?: string | null, message?: string | null, startAt?: any | null, userEmail: string, userId?: string | null };

export const StatusFragmentFragmentDoc = gql`
    fragment StatusFragment on userStatus {
  emoji
  status
  endAt
  integrationAmieEndAt
  integrationAmieEventId
  integrationAmieStartAt
  integrationAmieTitle
  message
  startAt
  userEmail
  userId
}
    `;
export const UserDocument = gql`
    query User {
  new_user {
    id
    photo_url
    display_name
    email
    spotifyRefreshToken
    zoomRefreshToken
    status {
      ...StatusFragment
    }
    preferences {
      id
      todoPrivacy
      showDoneTodos
      showArchivedLists
      videoProvider
      theme
      calendarStartsOn
      defaultDuration
      zoomLink
      dateFormat
      ui24HourClock
      aroundLink
      timezone
      uiHideWeekend
      uiVisibleCalendarsId
      defaultEventColor
      hasOnboarded
      defaultEventColor
      workHoursStartTime
      workHoursEndTime
      workHoursEnabled
      hideDeclinedEvents
    }
  }
}
    ${StatusFragmentFragmentDoc}`;

export function useUserQuery(options?: Omit<Urql.UseQueryArgs<UserQueryVariables>, 'query'>) {
  return Urql.useQuery<UserQuery>({ query: UserDocument, ...options });
};