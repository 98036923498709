import React, { useCallback, useEffect, useRef } from 'react';
import createCtx from './createCtx';
import { HotkeyScope } from 'types/hotkeys';
import hotkeys from 'hotkeys-js';

type THotkeyContext = {
  getScope: () => HotkeyScope;
  setScope: (scope: HotkeyScope) => void;
  setPreviousScope: (scope?: HotkeyScope) => void;
  resetScope: () => void;
};

export const [useHotkeyContext, HotkeyContext] = createCtx<THotkeyContext>();

export const HotkeyProvider: React.FC = ({ children }) => {
  const initialScope = useRef<HotkeyScope>('global');
  const scopeStack = useRef<HotkeyScope[]>([initialScope.current]);

  const getScope = useCallback(() => hotkeys.getScope() as HotkeyScope, []);
  const setScope = useCallback((newScope: HotkeyScope, ignoreStack = false) => {
    if (newScope === undefined) return;
    if (
      !ignoreStack &&
      scopeStack.current[scopeStack.current.length - 1] !== newScope
    ) {
      scopeStack.current.push(newScope);
    }

    hotkeys.setScope(newScope);
  }, []);

  const setPreviousScope = useCallback(
    (scope?: HotkeyScope) => {
      if (scopeStack.current.length === 1) return;

      // To avoid a race condition, you can provide a scope so that we only pop
      // to the previous IFF the current scope is the one you provided.
      if (
        scope != null &&
        scope !== scopeStack.current[scopeStack.current.length - 1]
      )
        return;

      scopeStack.current.pop();

      setScope(scopeStack.current[scopeStack.current.length - 1], true);
    },
    [setScope]
  );

  const resetScope = useCallback(() => {
    hotkeys.setScope(initialScope.current);
  }, []);

  // Set initial scope on component mount
  useEffect(resetScope, [resetScope]);

  return (
    <HotkeyContext value={{ getScope, setScope, setPreviousScope, resetScope }}>
      {children}
    </HotkeyContext>
  );
};
