import { selectAtom, useAtomCallback, useAtomValue } from 'jotai/utils';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { fullUserAtom, userAtom } from 'hooks/user/userAtoms';

export const userIdAtom = selectAtom(userAtom, (user) => user?.uid);

const userNameAtom = selectAtom(userAtom, (user) => user?.displayName);
const userFirebaseIdValidAtom = selectAtom(userAtom, (user) =>
  isTokenValid(user?.firebaseIdToken)
);
const userAvatarAtom = selectAtom(userAtom, (user) => user?.photo || undefined);

export function useUserId() {
  return useAtomValue(userIdAtom);
}

export function useGetUserId() {
  return useAtomCallback((get) => get(userIdAtom));
}

export function useUserName() {
  return useAtomValue(userNameAtom);
}
export function useIsFirebaseIdValid() {
  return useAtomValue(userFirebaseIdValidAtom);
}

export function useUserAvatar() {
  return useAtomValue(userAvatarAtom);
}

export function useFullUser() {
  return useAtomValue(fullUserAtom);
}
export function useUser() {
  return useAtomValue(userAtom);
}

export const userEmailAtom = selectAtom(userAtom, (user) => user?.email);

export function useUserEmail() {
  return useAtomValue(userEmailAtom);
}

export function usePrimaryCalendarId() {
  return useUserEmail() || 'default';
}

export function useIsWorkEmail(): boolean {
  const email = useUserEmail();
  return !!email && !email.includes('@gmail');
}

export function isTokenValid(token?: string | null): boolean {
  if (!token) return false;
  try {
    const decodedToken = jwtDecode<JwtPayload>(token);
    const expiresAt = decodedToken.exp;
    if (!expiresAt) return false;
    return expiresAt * 1000 > Date.now();
  } catch (e) {
    console.error(e);
    return false;
  }
}
