import { AnimatePresence, motion } from 'framer-motion';
import useIsShareLinkReady from 'hooks/calendarLink/booking/useIsShareLinkReady';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import {
  isHomePage,
  isLoginPage,
  isPopupPage,
  isProfilePage,
  isRootPage,
  isShareLinkPage,
  isSupportPage,
} from 'utils/router';
import { perfEnd, perfStart } from 'contexts/performance';
import { useIsProfileReady } from 'hooks/useProfile';
import useMounted from 'hooks/useMounted';
import { useUser } from 'hooks/auth/authAtoms';
import { useIsPreferencesReady } from 'hooks/usePreferences';
import { useOnboarding } from './Onboarding/useOnboarding';

const PageLoading: React.FC = ({ children }) => {
  const router = useRouter();
  const { isReady } = router;
  const user = useUser();
  const isRoot = isRootPage(router);
  const isHome = isHomePage(router);
  const isProfile = isProfilePage(router);
  const isShareLink = isShareLinkPage(router);
  const isSupport = isSupportPage(router);
  const isPopup = isPopupPage(router);
  const isPreferencesReady = useIsPreferencesReady();
  const isShareLinkReady = useIsShareLinkReady();
  const isProfileReady = useIsProfileReady();
  const isMounted = useMounted();
  const { shouldOnboard } = useOnboarding();

  const allowProfile = isProfile && isProfileReady && isReady;
  const allowHome = isHome && !!user && isPreferencesReady;
  const allowGrid = isRoot && !!user && isPreferencesReady;
  const allowLogin = isLoginPage(router) && isReady;
  const allowShareLink = isShareLink && isShareLinkReady && isReady;
  const allowOnboarding = shouldOnboard && isReady;

  const isLoaderHidden =
    allowProfile ||
    allowGrid ||
    allowShareLink ||
    allowHome ||
    allowLogin ||
    allowOnboarding ||
    isSupport ||
    isPopup;

  useEffect(() => {
    if (isLoaderHidden) {
      perfEnd('loading-page');
    } else {
      perfStart('loading-page');
    }
  }, [isLoaderHidden]);

  // Fallback loader
  return (
    <>
      <AnimatePresence>
        {!isLoaderHidden && (
          <motion.div
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 1.2 }}
            className="bg-background fixed top-0 left-0 z-100 flex h-screen w-screen items-center justify-center"
          >
            <p className="sr-only">Loading</p>
            <svg
              className="inline-block animate-pageLoad text-amie-pink"
              height={60}
              width={60}
              viewBox="0 0 460 460"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                className="stroke-gray-200 dark:stroke-gray-600"
                x="15"
                y="15"
                width="430"
                height="430"
                rx="143"
                fill="currentColor"
                strokeWidth="30"
              />
              <path
                className="stroke-gray-200"
                d="M132.534 329.82L132.27 330.5H133H168.408H168.753L168.875 330.177L178.296 305.287H280.023L289.583 330.179L289.706 330.5H290.05H326.314H327.043L326.78 329.82L249.398 129.82L249.274 129.5H248.931L210.383 129.5H210.04L209.916 129.82L132.534 329.82ZM191.25 271.439L229.227 172.591L266.951 271.439H191.25Z"
                fill="white"
              />
            </svg>
          </motion.div>
        )}
      </AnimatePresence>

      {/* isReady is `undefined` on first render, so we can easily skip past all the hydration issues we'd normally have */}
      {/* isMounted needs to be ready or there is a dom mismatch warning from React */}
      {isReady && isMounted && children}
    </>
  );
};

export default PageLoading;
