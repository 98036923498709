import { useEffect } from 'react';
import {
  CalendarsQuery,
  useCalendarsQuery,
} from 'graphql/queries/calendars.graphql';
import {
  OwnCalendarListQuery,
  useOwnCalendarListQuery,
} from 'graphql/queries/OwnCalendarList.graphql';
import { useUser } from 'hooks/auth/authAtoms';
import { atomWithStorage, useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useIsGridEventsReady } from './events/useGridEvents';

export const allCalendarsAtom = atomWithStorage<CalendarsQuery['calendar']>(
  'allCalendarsAtom',
  []
);
export const ownCalendarsAtom = atomWithStorage<
  OwnCalendarListQuery['ownCalendarList']
>('ownCalendarsAtom', []);

export function useCalendars() {
  return useAtomValue(allCalendarsAtom);
}
export function useOwnCalendars() {
  return useAtomValue(ownCalendarsAtom);
}
export function SyncCalendars() {
  const user = useUser();
  const pause = !user; // null if not set
  const [queryResults] = useCalendarsQuery({ pause });
  const eventsReady = useIsGridEventsReady();
  const [ownCalendarsResults] = useOwnCalendarListQuery({
    pause: !eventsReady,
  });
  const setCalendars = useUpdateAtom(allCalendarsAtom);
  const setOwnCalendars = useUpdateAtom(ownCalendarsAtom);

  useEffect((): void => {
    if (!queryResults.data) return;
    setCalendars(queryResults.data.calendar || []);
  }, [queryResults.data, setCalendars]);

  useEffect(() => {
    if (!ownCalendarsResults.data) return;
    setOwnCalendars(ownCalendarsResults.data.ownCalendarList || []);
  }, [ownCalendarsResults.data, setOwnCalendars]);

  return null;
}
