/* 85758c9351abaa8bdb766322de92dfd701d7e13c
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TodosByCategoryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TodosByCategoryQuery = { __typename?: 'query_root', category: Array<{ __typename?: 'category', expanded: boolean, archivedAt?: any | null, deletedAt?: any | null, createdAt: any, id: any, name?: string | null, index?: number | null, todos: Array<{ __typename?: 'todo', archivedAt?: any | null, createdAt: any, doneAt?: any | null, deletedAt?: any | null, id: any, index?: number | null, name?: string | null, size: number, type?: Types.TodoTypeEnum_Enum | null }> }> };

export type TodosQueryVariables = Types.Exact<{
  hideArchived: Types.Scalars['Boolean'];
}>;


export type TodosQuery = { __typename?: 'query_root', todos: Array<{ __typename?: 'todo', type?: Types.TodoTypeEnum_Enum | null, size: number, name?: string | null, id: any, doneAt?: any | null, deletedAt?: any | null, createdAt: any, updatedAt: any, categoryId?: any | null }>, categories: Array<{ __typename?: 'category', name?: string | null, rank?: string | null, id: any, createdAt: any, colorFamily?: Types.ColorFamilyEnum_Enum | null, deletedAt?: any | null, archivedAt?: any | null, expanded: boolean, todos: Array<{ __typename?: 'todo', id: any, __name?: string | null }> }> };


export const TodosByCategoryDocument = gql`
    query TodosByCategory {
  category(order_by: {index: asc}) {
    todos(order_by: {index: asc}) {
      archivedAt
      createdAt
      doneAt
      deletedAt
      id
      index
      name
      size
      type
    }
    expanded
    archivedAt
    deletedAt
    createdAt
    id
    name
    index
  }
}
    `;

export function useTodosByCategoryQuery(options?: Omit<Urql.UseQueryArgs<TodosByCategoryQueryVariables>, 'query'>) {
  return Urql.useQuery<TodosByCategoryQuery>({ query: TodosByCategoryDocument, ...options });
};
export const TodosDocument = gql`
    query Todos($hideArchived: Boolean!) {
  todos: todo(
    where: {category: {deletedAt: {_is_null: true}, archivedAt: {_is_null: $hideArchived}}, deletedAt: {_is_null: true}}
  ) {
    type
    size
    name
    id
    doneAt
    deletedAt
    createdAt
    updatedAt
    categoryId
  }
  categories: category(
    order_by: {rank: asc}
    where: {archivedAt: {_is_null: $hideArchived}, deletedAt: {_is_null: true}}
  ) {
    name
    rank
    id
    createdAt
    colorFamily
    deletedAt
    archivedAt
    expanded
    todos(
      order_by: {rank: asc}
      where: {archivedAt: {_is_null: true}, deletedAt: {_is_null: true}}
    ) {
      id
      __name: name
    }
  }
}
    `;

export function useTodosQuery(options: Omit<Urql.UseQueryArgs<TodosQueryVariables>, 'query'>) {
  return Urql.useQuery<TodosQuery>({ query: TodosDocument, ...options });
};