import { ColorFamily } from '@graphql-types@';
import { atom } from 'jotai';
import { groupBy } from 'lodash';
import { TCalendar } from 'types/calendar';
import { IGridEvent } from 'types/events';
import { colorOrDefault } from 'utils/eventColors';

type ColorFamilyRanking = ColorFamily[];

export const reverseColorFamilyRankingAtom = atom<ColorFamily[]>([]);

export const getCalendarEventColorFamilyOverrides = (
  colorFamilyRanking: ColorFamilyRanking,
  calendars: TCalendar[],
  calendarIdsToShow: TCalendar['id'][]
) => {
  const primaryCalendar = calendars.find((calendar) => calendar.isPrimary);
  const reverseColorFamilyRanking = Array.from(colorFamilyRanking).reverse();

  return calendarIdsToShow
    .filter((calendarId) => calendarId !== primaryCalendar?.id)
    .reduce<CalendarEventColorFamilyOverrides>(
      (mapping, calendarId, index) => ({
        ...mapping,
        [calendarId]:
          reverseColorFamilyRanking[index % reverseColorFamilyRanking.length],
      }),
      {}
    );
};

export const getPreferredColorFamilyRanking = (
  events: Array<Pick<IGridEvent, 'isOwnEvent' | 'colorFamily'>>
): ColorFamilyRanking => {
  const ownEvents = events
    .filter((e) => e.isOwnEvent)
    .map((event) => ({
      ...event,
      colorFamily: event.colorFamily || ColorFamily.Gray,
    }));

  return getColorFamilyRanking(ownEvents);
};

export type CalendarEventColorFamilyOverrides = Record<
  TCalendar['id'],
  ColorFamily | undefined
>;

const getColorFamilyRanking = (
  events: Array<Pick<IGridEvent, 'isOwnEvent' | 'colorFamily'>>
): ColorFamilyRanking => {
  const eventsGroupedByColorFamily = groupBy(
    events,
    (event) => event.colorFamily
  );

  return Object.values(ColorFamily).sort((a, b) =>
    (eventsGroupedByColorFamily[a] || []).length >
    (eventsGroupedByColorFamily[b] || []).length
      ? -1
      : 1
  );
};
