import { atom, useAtom } from 'jotai';
import {
  atomWithStorage,
  selectAtom,
  useAtomCallback,
  useAtomValue,
  useUpdateAtom,
} from 'jotai/utils';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import {
  LOCALSTORAGE_ITEM_RECENT_TIMEZONES,
  LOCALSTORAGE_ITEM_TIMEZONE_PREFERENCES,
} from 'utils/constants';
import { EmojiData } from 'emoji-mart';
import { getBrowserTimezone } from 'utils/time';
import { selectedDateAtom } from './calendarLink/booking/atoms';
import { preferencesAtom } from './preferences/preferencesAtoms';
import { currentDateAtom } from './useCalendar';
import { filterTimezones } from './useTimezoneSearch';
import { PreferenceName } from 'types/preference';
import { useUpdatePreference } from './usePreferences';
import { uuid } from 'utils/toolbox';
import { DateTime } from 'luxon';

export const maxNumTimezones = 5;

export interface TimezonePreference {
  timezone: string;
  nickname?: string;
  city?: string;
  emoji: EmojiData | null;
  id?: string;
  priority: 'primary' | 'secondary';
}

const timezonePreferencesSourceAtom = atomWithStorage<TimezonePreference[]>(
  LOCALSTORAGE_ITEM_TIMEZONE_PREFERENCES,
  []
);

export const timezonePreferencesAtom = atom<
  TimezonePreference[],
  TimezonePreference
>(
  (get) => {
    return get(timezonePreferencesSourceAtom);
  },
  (get, set, editedTimezonePreference) => {
    const oldPreferences = get(timezonePreferencesSourceAtom);
    const clonedPreferences = [...oldPreferences];

    const currentIndex = clonedPreferences.findIndex(
      (x) => x.id === editedTimezonePreference.id
    );

    // Assign an identifier if it doesn't already have one.
    editedTimezonePreference.id = editedTimezonePreference.id || uuid();

    if (editedTimezonePreference.priority === 'primary') {
      // Mark all others as secondary.
      clonedPreferences.forEach((x) => (x.priority = 'secondary'));

      if (currentIndex !== -1) {
        // Remove it from the array.
        clonedPreferences.splice(currentIndex, 1);
      }

      // Add it back at the beginning.
      clonedPreferences.unshift(editedTimezonePreference);
    } else {
      if (currentIndex !== -1) {
        clonedPreferences[currentIndex] = editedTimezonePreference;
      } else {
        clonedPreferences.push(editedTimezonePreference);
      }

      // Make sure that the first one is marked as primary still,
      // in case we toggled it back and forth while editing.
      clonedPreferences[0].priority = 'primary';
    }

    set(timezonePreferencesSourceAtom, clonedPreferences);
  }
);

export const useRemoveTimezone = () => {
  return useAtomCallback((get, set, id: string | undefined) => {
    const oldPreferences = get(timezonePreferencesAtom);

    if (oldPreferences.length === 1) {
      // If you remove the last timezone, then repopulate the timezones with the local timezone.
      // One timezone is always required.
      const newPreferences: TimezonePreference[] = [
        {
          timezone: DateTime.now().zone.name,
          priority: 'primary',
          emoji: null,
          id: uuid(),
        },
      ];

      set(timezonePreferencesSourceAtom, newPreferences);
    } else {
      const newPreferences = [...oldPreferences];
      newPreferences.splice(
        oldPreferences.findIndex((x) => x.id === id),
        1
      );

      newPreferences[0].priority = 'primary';
      set(timezonePreferencesSourceAtom, newPreferences);
    }
  });
};

const validateTimeZone = (
  candidate?: string
): { isValid: boolean; tz: string } => {
  if (candidate === undefined) {
    return { isValid: false, tz: '' };
  }

  try {
    Intl.DateTimeFormat(undefined, { timeZone: candidate });
    return { isValid: true, tz: candidate };
  } catch (_err) {
    return { isValid: false, tz: '' };
  }
};

const initialTimezone = (preferredTZ?: string): string => {
  const { isValid, tz } = validateTimeZone(preferredTZ);
  if (isValid) {
    return tz;
  }

  return getBrowserTimezone();
};

export const timezoneAtom = selectAtom(
  atom((get) => {
    initialTimezone();
    const { timezone } = get(preferencesAtom);
    const { isValid, tz } = validateTimeZone(timezone);
    return isValid ? tz : initialTimezone();
  }),
  (timezone) => timezone,
  (prev, next) => prev === next
);

const maxRecentTimezones = 3;

// TODO: Should we just delete this and use useAtomValue whenever we need it?
export default function useTimezone(): string {
  return useAtomValue(timezoneAtom);
}

export function SyncTimezone(): null {
  const timezone = useAtomValue(timezoneAtom);
  const setRecentTimezones = useUpdateAtom(recentTimezoneAtom);
  const [currentDate, setCurrentDate] = useAtom(currentDateAtom);
  const [selectedDate, setSelectedDate] = useAtom(selectedDateAtom);

  const [timezonePreferences] = useAtom(timezonePreferencesAtom);
  const setTimezonePreferences = useUpdateAtom(timezonePreferencesSourceAtom);
  const updatePreferences = useUpdatePreference();

  const primaryTimezone = useMemo(
    () => timezonePreferences?.[0]?.timezone,
    [timezonePreferences]
  );

  useEffect(() => {
    const serializedTimezones = localStorage.getItem(
      LOCALSTORAGE_ITEM_RECENT_TIMEZONES
    );

    const timezonesInStorage = serializedTimezones
      ? serializedTimezones.split(',')
      : [];
    const newRecentTimezones = _.uniq(
      [timezone].concat(timezonesInStorage)
    ).slice(0, maxRecentTimezones);

    localStorage.setItem(
      LOCALSTORAGE_ITEM_RECENT_TIMEZONES,
      newRecentTimezones.join()
    );

    setRecentTimezones(newRecentTimezones);
  }, [timezone, setRecentTimezones]);

  useEffect(() => {
    setCurrentDate(currentDate.setZone(timezone));
    setSelectedDate(selectedDate.setZone(timezone));
  }, [currentDate, selectedDate, setCurrentDate, setSelectedDate, timezone]);

  // This sets the default timezone.
  useEffect(() => {
    if (timezonePreferences.length > 0) return;

    const newPreferences: TimezonePreference[] = [
      {
        timezone: timezone,
        emoji: null,
        priority: 'primary',
        city: filterTimezones(timezone, 1, true)?.[0]?.locations[0],
        id: uuid(),
      },
    ];

    setTimezonePreferences(newPreferences);
  }, [setTimezonePreferences, timezone, timezonePreferences.length]);

  useEffect(() => {
    localStorage.setItem(
      LOCALSTORAGE_ITEM_TIMEZONE_PREFERENCES,
      JSON.stringify(timezonePreferences)
    );
  }, [timezonePreferences]);

  useEffect(() => {
    if (!primaryTimezone) return;

    updatePreferences(PreferenceName.Timezone, primaryTimezone);
  }, [primaryTimezone, updatePreferences]);

  return null;
}

export const recentTimezoneAtom = atom(
  typeof localStorage === 'undefined'
    ? []
    : localStorage.getItem(LOCALSTORAGE_ITEM_RECENT_TIMEZONES)?.split(',') || []
);
