import { DateTime } from 'luxon';
import { getTimeZones, TimeZone } from '@vvo/tzdb';
import _ from 'lodash';

export interface DisplayTimezone {
  locations: string[];
  tzCode: string;
  timeDiff: string;
  abbreviation: string;
  searchString: string;
}

const prioritizedCities = [
  'New York City',
  'Los Angeles',
  'Berlin',
  'London',
  'Tokyo',
  'Delhi',
  'Sao Paulo',
  'Mexico City',
  'Moscow',
  'Bangkok',
  'Rio',
  'Paris',
  'Cape Town',
  'Sydney',
  'Jakarta',
];

const generatedTimezones = getTimeZones();
const prioritizedTimezones = _.flatMap(
  prioritizedCities,
  (x) => generatedTimezones.find((y) => y.mainCities[0] === x) || []
);

function offsetToTimeDiff(tz: TimeZone) {
  const dateInTz = DateTime.now().setZone(tz.name);
  const targetOffsetUtcMinutes = dateInTz.offset;
  const localOffsetUtcMinutes = DateTime.now().offset;
  const totalOffset = targetOffsetUtcMinutes - localOffsetUtcMinutes;

  if (!totalOffset) {
    return 'Same time';
  }

  const sign = totalOffset < 0 ? 'behind' : 'ahead';
  const hours = Math.abs(Math.floor(totalOffset / 60));
  const minutes = Math.abs(Math.round(totalOffset % 60));
  return `${hours}h ${minutes ? minutes + 'm ' : ''}${sign}`;
}

export const timezoneList: DisplayTimezone[] = _.uniqBy(
  prioritizedTimezones.concat(generatedTimezones),
  'name'
).map((tz) => {
  return {
    locations: tz.mainCities,
    tzCode: tz.name,
    timeDiff: offsetToTimeDiff(tz),
    abbreviation: tz.abbreviation,
    searchString: [tz.name, tz.abbreviation, ...tz.mainCities]
      .join()
      .toLowerCase(),
  };
});

export const timezoneListByCity: DisplayTimezone[] = _.flatMap(
  _.uniqBy(prioritizedTimezones.concat(generatedTimezones), 'name'),
  (tz) => {
    return tz.mainCities.map((city) => ({
      locations: [city],
      tzCode: tz.name,
      timeDiff: offsetToTimeDiff(tz),
      abbreviation: tz.abbreviation,
      searchString: [tz.name, tz.abbreviation, city].join().toLowerCase(),
    }));
  }
);

export const convertNamesToDisplayTimezone = (
  timezones: string[]
): DisplayTimezone[] => {
  return _.flatMap(
    timezones,
    (x) => timezoneList.find((y) => y.tzCode === x) || []
  );
};

export const filterTimezones = (
  term: string,
  limit: number,
  breakoutByCity = false
): DisplayTimezone[] => {
  const lowercaseTerm = term.toLowerCase();

  return (breakoutByCity ? timezoneListByCity : timezoneList)
    .filter((x) => x.searchString.includes(lowercaseTerm))
    .slice(0, limit);
};
