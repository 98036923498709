/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

const performanceMeasurements: Record<string, number> = {};

const enableDataDogLogging = process.env.NODE_ENV !== 'development';
let enableConsole = false;

const INIT_TIME = Date.now();

export function perfMark(name: string): number {
  if (process.env.NODE_ENV === 'test') return 0;
  performance.mark(name);
  const timestampMS = Date.now() - INIT_TIME;
  if (enableConsole) {
    console.info(`${name} at: ${timestampMS}ms`);
  }
  if (enableDataDogLogging) {
    datadogRum.addAction('perf-mark', {
      name,
      timestampMS,
    });
  }
  return timestampMS;
}

export function perfStart(name: string): void {
  if (process.env.NODE_ENV === 'test') return;
  performance.mark(name);
  if (performanceMeasurements[name]) {
    return;
  }
  performanceMeasurements[name] = performance.now();
}

export function perfEnd(name: string): void {
  if (process.env.NODE_ENV === 'test') return;
  if (!performanceMeasurements[name]) {
    return;
  }
  const start = performanceMeasurements[name];
  const end = performance.now();
  const durationMS = Math.round(end - start);
  delete performanceMeasurements[name];
  // Here we can log the performance measurements to a service
  if (enableConsole) {
    console.info(`${name} took ${durationMS}ms`);
  }
  if (enableDataDogLogging) {
    datadogRum.addAction('perf-measurement', {
      name,
      durationMS,
    });
  }
}

export function usePerfEnd(name: string): void {
  useEffect(() => {
    perfEnd(name);
  }, []);
}

/**
 * You can enable and disable the logging of performance measurements in the console
 * by running the following command:
 * - showPerformanceLogging()
 * - hidePerformanceLogging()
 */

try {
  if (typeof window !== 'undefined') {
    enableConsole = localStorage.getItem('enablePerfConsole') === 'true';
    window.showPerformanceLogging = () => {
      enableConsole = true;
      localStorage.setItem('enablePerfConsole', 'true');
    };
    window.hidePerformanceLogging = () => {
      enableConsole = false;
      localStorage.setItem('enablePerfConsole', 'false');
    };
  }
} catch (e) {
  // ignore
  // Swallow errors in case of SSR
}
