/* 252565a744e0d6a40917d5ac5c4257ab3a1d3f42
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ContactsQueryVariables = Types.Exact<{
  currentUserEmail?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ContactsQuery = { __typename?: 'query_root', new_contact: Array<{ __typename?: 'new_contact', id: any, emailAddress: string, labels: any, lastInteractedAt: any, avatar?: string | null, displayName?: string | null, favoriteAt?: any | null, notes?: string | null }> };

export type ContactFragmentFragment = { __typename?: 'new_contact', id: any, emailAddress: string, labels: any, lastInteractedAt: any, avatar?: string | null, displayName?: string | null, favoriteAt?: any | null, notes?: string | null };

export type ContactsByFavoriteQueryVariables = Types.Exact<{
  domainEndsWith?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ContactsByFavoriteQuery = { __typename?: 'query_root', new_contact: Array<{ __typename?: 'new_contact', id: any, emailAddress: string, labels: any, lastInteractedAt: any, avatar?: string | null, displayName?: string | null, favoriteAt?: any | null, notes?: string | null, status?: { __typename?: 'userStatus', emoji?: string | null, status: string, endAt?: any | null, integrationAmieEndAt?: any | null, integrationAmieEventId?: string | null, integrationAmieStartAt?: any | null, integrationAmieTitle?: string | null, message?: string | null, startAt?: any | null, userEmail: string, userId?: string | null } | null }> };

export type StatusFragmentFragment = { __typename?: 'userStatus', emoji?: string | null, status: string, endAt?: any | null, integrationAmieEndAt?: any | null, integrationAmieEventId?: string | null, integrationAmieStartAt?: any | null, integrationAmieTitle?: string | null, message?: string | null, startAt?: any | null, userEmail: string, userId?: string | null };

export type ContactFavoriteAtQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type ContactFavoriteAtQuery = { __typename?: 'query_root', new_contact_by_pk?: { __typename?: 'new_contact', id: any, emailAddress: string, labels: any, lastInteractedAt: any, avatar?: string | null, displayName?: string | null, favoriteAt?: any | null, notes?: string | null } | null };

export type ContactReminderEventQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ContactReminderEventQuery = { __typename?: 'query_root', contactReminder?: { __typename?: 'ContactReminderOutput', recurrenceRules: Array<string>, id: string, reminderEventId?: string | null, email: string, firstName?: string | null, lastName?: string | null } | null };

export const ContactFragmentFragmentDoc = gql`
    fragment ContactFragment on new_contact {
  id
  emailAddress
  labels
  lastInteractedAt
  avatar
  displayName
  favoriteAt
  notes
}
    `;
export const StatusFragmentFragmentDoc = gql`
    fragment StatusFragment on userStatus {
  emoji
  status
  endAt
  integrationAmieEndAt
  integrationAmieEventId
  integrationAmieStartAt
  integrationAmieTitle
  message
  startAt
  userEmail
  userId
}
    `;
export const ContactsDocument = gql`
    query Contacts($currentUserEmail: String) {
  new_contact(
    order_by: {lastInteractedAt: desc_nulls_last, favoriteAt: desc_nulls_last}
    where: {_not: {emailAddress: {_eq: $currentUserEmail}}}
  ) {
    ...ContactFragment
  }
}
    ${ContactFragmentFragmentDoc}`;

export function useContactsQuery(options?: Omit<Urql.UseQueryArgs<ContactsQueryVariables>, 'query'>) {
  return Urql.useQuery<ContactsQuery>({ query: ContactsDocument, ...options });
};
export const ContactsByFavoriteDocument = gql`
    query ContactsByFavorite($domainEndsWith: String) {
  new_contact(
    order_by: {favoriteAt: asc_nulls_last}
    where: {_and: [{_not: {favoriteAt: {_is_null: true}}}, {emailAddress: {_like: $domainEndsWith}}]}
  ) {
    ...ContactFragment
    status {
      ...StatusFragment
    }
  }
}
    ${ContactFragmentFragmentDoc}
${StatusFragmentFragmentDoc}`;

export function useContactsByFavoriteQuery(options?: Omit<Urql.UseQueryArgs<ContactsByFavoriteQueryVariables>, 'query'>) {
  return Urql.useQuery<ContactsByFavoriteQuery>({ query: ContactsByFavoriteDocument, ...options });
};
export const ContactFavoriteAtDocument = gql`
    query ContactFavoriteAt($id: uuid!) {
  new_contact_by_pk(id: $id) {
    ...ContactFragment
  }
}
    ${ContactFragmentFragmentDoc}`;

export function useContactFavoriteAtQuery(options: Omit<Urql.UseQueryArgs<ContactFavoriteAtQueryVariables>, 'query'>) {
  return Urql.useQuery<ContactFavoriteAtQuery>({ query: ContactFavoriteAtDocument, ...options });
};
export const ContactReminderEventDocument = gql`
    query ContactReminderEvent($id: ID!) {
  contactReminder(id: $id) {
    recurrenceRules
    id
    reminderEventId
    email
    firstName
    lastName
  }
}
    `;

export function useContactReminderEventQuery(options: Omit<Urql.UseQueryArgs<ContactReminderEventQueryVariables>, 'query'>) {
  return Urql.useQuery<ContactReminderEventQuery>({ query: ContactReminderEventDocument, ...options });
};