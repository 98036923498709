import { datadogRum } from '@datadog/browser-rum';
import { logout } from 'utils/logout';
import { fullUserAtom, userAtom } from 'hooks/user/userAtoms';
import { parseCookies, setCookie } from 'nookies';
import { useEffect, useState } from 'react';
import { getIsSecureCookieURL } from 'utils/urls';
import {
  COOKIE_NAME_FIREBASE_ID_TOKEN,
  COOKIE_NAME_OAUTH_TOKEN,
} from '../../utils/constants';
import { firebase } from '../../utils/services/firebaseShared';
import { DateTime } from 'luxon';
import { perfEnd, perfStart } from '../../contexts/performance';
import { useAtom } from 'jotai';
import usePrevious from 'hooks/usePrevious';
import { useUserQuery } from 'graphql/queries/user.graphql';
import { useUserSubscription } from 'graphql/subscriptions/user.graphql';
import { isTokenValid } from './authAtoms';

const auth = firebase.auth();

export function UseHandleAuth() {
  useHandleAuth();
  return null;
}

function doAuthCheck() {
  return fetch('/api/auth/check', {
    method: 'POST',
    headers: {
      // Cookies are needed along with this request.
      credentials: 'include',
    },
  }).then((res) => res.json());
}

export function useHandleAuth() {
  const [user, setUser] = useAtom(userAtom);
  const [, setFullUser] = useAtom(fullUserAtom);
  const prevUser = usePrevious(user);
  const [userQueryRes] = useUserQuery({
    pause: !user,
  });
  const [userSubscriptionRes] = useUserSubscription({
    pause: !user,
  });

  /**
   * Two different tokens exist due to how Firebase sign-in works.
   *
   * In a nutshell:
   * 1. id_token from provider (called a credential in firebase docs). Our auth provider is Google.
   * 2. id_token from Firebase (obtained after Firebase sign-in with provider credential).
   *
   * https://firebase.google.com/docs/auth/web/google-signin
   *
   */
  const [initialOa2Token, setInitialOa2Token] = useState<string | undefined>();
  const [firebaseToken, setFirebaseToken] = useState<string | undefined>();

  useEffect(() => {
    perfStart('loading-user');
  }, []);

  useEffect(() => {
    if (user && !prevUser) {
      perfEnd('loading-user');
    }
  }, [user, prevUser]);

  useEffect(() => {
    if (userQueryRes.data?.new_user[0]) {
      setFullUser(userQueryRes.data.new_user[0]);
    }
  }, [userQueryRes.data?.new_user, setFullUser]);

  useEffect(() => {
    if (userSubscriptionRes.data?.new_user[0]) {
      setFullUser(userSubscriptionRes.data.new_user[0]);
    }
  }, [userSubscriptionRes.data?.new_user, setFullUser]);

  useEffect(() => {
    if (user) {
      persistBasicUserInfo({
        id: user.uid,
        email: user.email || '',
        name: user.displayName || '',
      });

      if (process.env.NODE_ENV === 'production') {
        datadogRum.setUser({
          id: user.uid,
          email: user.email || undefined,
          name: user.displayName || undefined,
        });
      }
    }
  }, [user]);

  useEffect(() => {
    const getUpdatedToken = async () => {
      try {
        const response = await doAuthCheck();

        if (!response.oa2Token) {
          throw new Error('No token returned');
        }

        setInitialOa2Token(response.oa2Token);
      } catch (error) {
        console.warn('Unable to fetch token... ');
        logout();
      }
    };

    const cookies = parseCookies();
    const existingOauth2Token = cookies[COOKIE_NAME_OAUTH_TOKEN];
    const existingAccessToken = cookies[COOKIE_NAME_FIREBASE_ID_TOKEN];

    const isTimestampFromCookieValid =
      isTokenValid(existingAccessToken) && isTokenValid(existingOauth2Token);

    // Dirty check... is existing token from cookie still valid by timestamp?
    if (isTimestampFromCookieValid && existingOauth2Token) {
      setInitialOa2Token(existingOauth2Token);
      setFirebaseToken(existingAccessToken);
    } else {
      getUpdatedToken();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (process.env.STORYBOOK_MODE || !initialOa2Token) return;

    const googleCredential =
      firebase.auth.GoogleAuthProvider.credential(initialOa2Token);
    auth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => auth.signInWithCredential(googleCredential))
      .then(async ({ user }) => {
        if (!user) throw new Error('No user...');

        const newToken = await user.getIdToken();
        setFirebaseToken(newToken);
      })
      .catch((err) => {
        console.error('Cannnot sign in with cred, ', err);
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // const credential = error.credential;
      });
  }, [initialOa2Token]);

  useEffect(() => {
    if (process.env.STORYBOOK_MODE || !initialOa2Token || !firebaseToken)
      return;

    const unsubscribe = auth.onIdTokenChanged(async (resolvedUser) => {
      const firebaseIdToken = await resolvedUser?.getIdToken();

      if (!firebaseIdToken || !resolvedUser) {
        setUser(null);
        setFullUser(null);
        return logout();
      }

      const commonCookieOpts = {
        expires: DateTime.now().plus({ days: 14 }).toJSDate(),
        sameSite: 'lax' as const,
        path: '/',
        secure: getIsSecureCookieURL(),
      };

      setCookie(
        null,
        COOKIE_NAME_FIREBASE_ID_TOKEN,
        firebaseIdToken,
        commonCookieOpts
      );
      setCookie(
        null,
        COOKIE_NAME_OAUTH_TOKEN,
        initialOa2Token,
        commonCookieOpts
      );

      setUser({
        email: resolvedUser.email || '',
        uid: resolvedUser.uid,
        photo: resolvedUser.photoURL,
        displayName: resolvedUser.displayName || '',
        language: window.navigator.language,
        firebaseIdToken,
        didAuth: true,
      });
    });

    return () => unsubscribe();
  }, [firebaseToken, setUser, setFullUser, initialOa2Token]);

  useEffect(() => {
    if (process.env.STORYBOOK_MODE) return;

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        setUser(null);
        setFullUser(null);
      }
    });

    return () => unsubscribe();
  }, [setUser, setFullUser]);
}

export function persistBasicUserInfo(user: {
  email: string;
  name: string;
  id: string;
}) {
  localStorage.setItem(
    'amie:currentUser',
    JSON.stringify({
      id: user.id,
      email: user.email,
      name: user.name,
    })
  );
}

export function getPersistedBasicUserInfo() {
  const user = localStorage.getItem('amie:currentUser');
  if (!user) return null;
  return JSON.parse(user);
}

export async function getRefreshedFirebaseIdToken(): Promise<string> {
  const response = await doAuthCheck();

  if (!response.oa2Token) {
    throw new Error('No token returned');
  }

  const googleCredential = firebase.auth.GoogleAuthProvider.credential(
    response.oa2Token
  );

  const token = await auth
    .signInWithCredential(googleCredential)
    .then(async ({ user }) => {
      if (!user) throw new Error('No user...');
      const newToken = await user.getIdToken();
      return newToken;
    });

  if (!token) throw new Error('Unable to get new Firebase token');

  return token;
}
