const FULL_EMAIL_REGEX = /[\w-._]*[@][\w-_]+([.]{1}[\w-_]{2,})+$(?!\s)/i;

export const isEmail = (input: string): boolean => {
  return !!input.match(FULL_EMAIL_REGEX);
};

export function isSameDomain(a: string, b: string): boolean {
  if (!isEmail(a) || !isEmail(b)) return false;
  const aParts = a.split('@');
  const bParts = b.split('@');
  if (aParts.length !== 2 || bParts.length !== 2) return false;
  return aParts[1] === bParts[1];
}
