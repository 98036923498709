import { DateTime } from 'luxon';
import { CalendarSlotDate } from 'hooks/calendarLink/booking/atoms';
import { generateTimeSlots } from 'hooks/calendarLink/helpers/generateTimeSlotsHelper';
import type { CSSProperties } from 'react';
import RRule, { ByWeekday } from 'rrule';
import { CalendarLinkType } from 'hooks/calendarLink/calendarLinkAtoms';

export const DEFAULT_ROW_HEIGHT = 56;

export function getTimeSlotStyle(
  startAt: DateTime,
  endAt: DateTime,
  timeCols: string[]
): Pick<CSSProperties, 'gridRow' | 'gridColumn' | 'height' | 'top'> {
  const rowStart =
    timeCols.findIndex(
      (time) => startAt.set({ minute: 0 }).toFormat('HH:mm') === time
    ) + 1;
  const rowEnd =
    timeCols.findIndex(
      (time) => endAt.set({ minute: 0 }).toFormat('HH:mm') === time
    ) + 1;

  const hourDiff = endAt.diff(startAt, 'minutes').minutes / 60;
  const height = hourDiff * DEFAULT_ROW_HEIGHT;
  const top = (startAt.minute / 60) * DEFAULT_ROW_HEIGHT;

  return {
    top,
    gridRow: `${rowStart} / ${rowEnd}`,
    gridColumn: 3,
    height,
  };
}

export function getTimeSlotId({
  startAt,
  endAt,
}: {
  startAt: DateTime;
  endAt: DateTime;
}): string {
  return `${startAt.toISO()} ${endAt.toISO()}`;
}

export function getTimeFromSlotId(id: string): {
  startAt: DateTime;
  endAt: DateTime;
} {
  const [startAt, endAt] = id.split(' ');
  return {
    startAt: DateTime.fromISO(startAt),
    endAt: DateTime.fromISO(endAt),
  };
}

export function getHttpShareLink(linkId: string): string {
  return `${window.location.origin}/s/${linkId}`;
}

export function dateToRRuleWeekday(date: DateTime): ByWeekday {
  switch (date.toFormat('ccc')) {
    case 'Mon':
      return RRule.MO;
    case 'Tue':
      return RRule.TU;
    case 'Wed':
      return RRule.WE;
    case 'Thu':
      return RRule.TH;
    case 'Fri':
      return RRule.FR;
    case 'Sat':
      return RRule.SA;
    case 'Sun':
      return RRule.SU;
  }
  return RRule.MO;
}

export function sanitizeAlias(alias: string): string {
  return alias
    .toLowerCase()
    .replace(/[^a-z0-9\s-]/g, '')
    .replace(/\s+/gi, '-');
}

export function isCalendarLinkRecurring({
  slots,
}: {
  slots: CalendarSlotDate[];
}): boolean {
  return !!slots?.some(isSlotRecurring);
}

export function isSlotRecurring(calendarSlot: CalendarSlotDate): boolean {
  return calendarSlot.recurrenceRules?.length > 0;
}

export function getCalendarType({
  slots,
}: {
  slots: CalendarSlotDate[];
}): CalendarLinkType {
  return isCalendarLinkRecurring({ slots }) ? 'recurring' : 'one-off';
}

export function numberOfAvailableSlotsOnDay(props: {
  day: DateTime;
  slots: CalendarSlotDate[];
  durationMinutes: number;
  bufferDurationMinutes: number;
}): number {
  return generateTimeSlots({
    day: props.day,
    slots: props.slots,
    durationMinutes: props.durationMinutes,
    bufferDurationMinutes: props.bufferDurationMinutes,
  }).length;
}

export function getFirstName(fullname?: string): string {
  if (!fullname) {
    return '';
  }
  return fullname.split(' ')[0];
}

export function formatShareLink(
  link: { alias?: string; id: string },
  stripHttp = true
): string {
  const url = getHttpShareLink(link.alias || link.id);
  return stripHttp ? url.replace(/^https?:\/\//i, '') : url;
}
