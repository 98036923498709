export const requestIdleCallbackShim = (): void => {
  /**
   * Fallback for Safari...
   * https://developer.mozilla.org/en-US/docs/Web/API/Background_Tasks_API#falling_back_to_settimeout
   */
  if (typeof window !== 'undefined') {
    window.requestIdleCallback =
      window.requestIdleCallback ||
      function (handler) {
        const startTime = Date.now();

        return setTimeout(function () {
          handler({
            didTimeout: false,
            timeRemaining: function () {
              return Math.max(0, 50.0 - (Date.now() - startTime));
            },
          });
        }, 1);
      };
  }
};
