/* 3e16da17b61e1a42a6305d4f90e3566a0e653d03
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateProfileByPkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  _set: Types.Profile_Set_Input;
}>;


export type UpdateProfileByPkMutation = { __typename?: 'mutation_root', update_profile_by_pk?: { __typename?: 'profile', birthday?: any | null, createdAt: any, email: string, firstName?: string | null, handle?: string | null, id: any, lastName?: string | null, lastLocation?: string | null, updatedAt?: any | null } | null };


export const UpdateProfileByPkDocument = gql`
    mutation UpdateProfileByPk($id: uuid!, $_set: profile_set_input!) {
  update_profile_by_pk(pk_columns: {id: $id}, _set: $_set) {
    birthday
    createdAt
    email
    firstName
    handle
    id
    lastName
    lastLocation
    updatedAt
  }
}
    `;

export function useUpdateProfileByPkMutation() {
  return Urql.useMutation<UpdateProfileByPkMutation, UpdateProfileByPkMutationVariables>(UpdateProfileByPkDocument);
};