import {
  CategoryUpsertInput,
  TodoUpsertInput,
  UpdateTodosInput,
} from '@graphql-types@';
import { atom, Getter, Setter } from 'jotai';
import { atomWithReset, useUpdateAtom } from 'jotai/utils';
import { groupBy, merge, values } from 'lodash';

export const todosPoolAtom = atomWithReset<{
  categories: Array<CategoryUpsertInput>;
  todos: Array<TodoUpsertInput>;
}>({ categories: [], todos: [] });

const todosUpdatePoolAtom = atom(
  (get) => get(todosPoolAtom),
  (get: Getter, set: Setter, update: UpdateTodosInput) => {
    const pool = get(todosPoolAtom);

    const categories = update.categories
      ? mergeById([...pool.categories, ...update.categories])
      : pool.categories;

    const todos = update.todos
      ? mergeById([...pool.todos, ...update.todos])
      : pool.todos;

    set(todosPoolAtom, { categories, todos });
  }
);

export function useUpdateTodos() {
  return useUpdateAtom(todosUpdatePoolAtom);
}

function mergeById(arr: Array<{ id: string }>) {
  return values(groupBy(arr, 'id')).map((arr) => merge(...arr));
}
