import { NewEventFilter } from '@graphql-types@';
import { Cache, Data } from '@urql/exchange-graphcache';
import { CacheExchangeOpts } from '@urql/exchange-graphcache/dist/types';
import {
  UpdateEventMutation,
  UpdateEventMutationVariables,
} from 'graphql/mutations/UpdateEvent.graphql';
import { UpdateUserPreferencesMutation } from 'graphql/mutations/UpdateUserPreferences.graphql';
import {
  EventsDocument,
  EventsQuery,
  EventsQueryVariables,
} from 'graphql/queries/events.graphql';
import { UserDocument, UserQuery } from 'graphql/queries/user.graphql';
import { uniqBy } from 'lodash';
import { CreateEventMutation } from '../mutations/CreateEvent.graphql';
import {
  DeleteEventMutation,
  DeleteEventMutationVariables,
} from '../mutations/DeleteEvent.graphql';

type EventData = EventsQuery & Data;
type UserData = UserQuery & Data;

/**
 * Helper methods for working with cached data...
 */
const getEventVariablesFromCache = (cache: Cache): EventsQueryVariables[] => {
  const fields = cache.inspectFields({ __typename: 'Query' });
  const variablesCollection: EventsQueryVariables[] = [];

  // There can be many events queries, with different timeframes.
  // Collect all variables for all queries.

  fields.forEach((field) => {
    if (field.fieldName === 'event' && field.arguments) {
      const fieldArguments = field.arguments.where as NewEventFilter;

      if (!fieldArguments.inCalendars?.ids?.length) {
        throw new Error('Cache missing calendar ids for event');
      }
      if (!fieldArguments.startAt) {
        throw new Error('Cache is missing argument for start...');
      }
      if (!fieldArguments.endAt) {
        throw new Error('Cache is missing argument for end...');
      }

      const variables: EventsQueryVariables = {
        calendarIds: [],
        startAt: '',
        endAt: '',
      };

      variables.calendarIds = fieldArguments.inCalendars.ids;
      variables.startAt = fieldArguments.startAt;
      variables.endAt = fieldArguments.endAt;

      variablesCollection.push(variables);
    }
  });

  return variablesCollection;
};

const config: CacheExchangeOpts = {
  keys: {
    NewEventSchedule: () => null,
    EventsResponse: () => null,
    NewEvent: ({ compoundId }) => compoundId as string,
    userStatus: () => null,
    VideoConference: () => null,
    NewEventVideoConference: () => null,
    DisplayOptions: () => null,
    Preference: () => null,
    CalendarSynchronization: () => null,
    CalendarDisplayOptions: () => null,
    UserInfo: () => null,
    FindNextAvailableSlotResponse: () => null,
    AttendeeAvailability: () => null,
    Slot: () => null,
    OffsettedDay: () => null,
    EventRecurrenceRule: () => null,
    GMapsPlacesResponse: () => null,
    EventCreator: () => null,
    ProfileMeetInfoOutput: () => null,
  },
  resolvers: {
    NewEventSchedule: {
      endAt(parent) {
        if (typeof parent.endAt === 'string') {
          return new Date(parent.endAt);
        }
      },
      startAt(parent) {
        if (typeof parent.startAt === 'string') {
          return new Date(parent.startAt);
        }
      },
    },
  },
  updates: {
    Mutation: {
      update_new_contact_by_pk: (_result, _args, cache, _info) => {
        cache
          .inspectFields({ __typename: 'Query' })
          .filter((field) => field.fieldName === 'new_contact')
          .forEach((field) => {
            cache.invalidate('Query', field.fieldName, field.arguments);
          });
      },
      update_userPreferences: (_result, _args, cache, _info) => {
        const result = _result as UpdateUserPreferencesMutation;

        cache.updateQuery({ query: UserDocument }, (data: UserData | null) => {
          if (!data?.new_user[0]) return data;

          const returned = result.update_userPreferences?.returning[0];
          if (!returned) return data;

          delete returned.__typename;

          data.new_user[0].preferences = {
            ...data.new_user[0].preferences,
            ...returned,
          };

          return data;
        });
      },

      updateEvent: (_result, _args, cache, _info) => {
        const result = _result as UpdateEventMutation;
        const args = _args as UpdateEventMutationVariables;
        const allEventsQueryVariables = getEventVariablesFromCache(cache);

        allEventsQueryVariables.forEach((variables) => {
          if (variables.startAt !== '' && variables.endAt !== '') {
            cache.updateQuery(
              { query: EventsDocument, variables: variables },
              (_data) => {
                const data = _data as EventData;
                if (!data?.event) return data;
                const cachedEvents = data.event;

                if (Array.isArray(cachedEvents) && result.updateEvent) {
                  const updatedEvent = result.updateEvent.event;

                  if (
                    updatedEvent &&
                    'id' in updatedEvent &&
                    !updatedEvent.recurrenceRules?.length
                  ) {
                    data.event.events = [updatedEvent, ...cachedEvents];
                  }
                }

                data.event.events = uniqBy(data.event.events, (e) => e.id);

                return data;
              }
            );
          }
        });
      },
      createEvent: (_result, _args, cache, _info) => {
        const result = _result as CreateEventMutation;
        const allEventsQueryVariables = getEventVariablesFromCache(cache);

        allEventsQueryVariables.forEach((variables) => {
          if (variables.startAt !== '' && variables.endAt !== '') {
            cache.updateQuery({ query: EventsDocument, variables }, (_data) => {
              const data = _data as EventData;
              if (!data?.event) return data;

              if (Array.isArray(data.event) && result.createEvent) {
                const { event } = result.createEvent;
                if (event && 'id' in event) {
                  data.event.push(event);
                }
              }

              return data;
            });
          }
        });
      },

      deleteEvent: (_result, _args, cache, _info) => {
        const args = _args as DeleteEventMutationVariables;
        const result = _result as DeleteEventMutation;
        const allEventsQueryVariables = getEventVariablesFromCache(cache);

        allEventsQueryVariables.forEach((variables) => {
          cache.updateQuery({ query: EventsDocument, variables }, (_data) => {
            const data = _data as EventData;
            if (!data?.event) return data;

            if (Array.isArray(data.event) && result.deleteEvent) {
              data.event.events = data.event.filter(
                (e) => e.id !== args.eventId
              );
            }

            return data;
          });
        });
      },
    },
    Subscription: {
      calendarHistory: () => {
        /**
         * Do not handle calendarHistory subscription in here
         * to invalidate cache. It will create errors for subsequent
         * optimistic updates. Instead, refetching events when a subscription
         * event comes in. Look at where `useCalendarHistorySubscription` is
         * being initialized.
         */
      },
    },
  },
};

export default config;
