import { debounce, throttle } from 'lodash';
import { useEffect, useRef } from 'react';
import { v4 } from 'uuid';

/**
 * Keep the typing of the object
 */
export function ObjectKeys<T>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const debounceAction = debounce((action: Function) => action(), 500);
// eslint-disable-next-line @typescript-eslint/ban-types
export const throttleAction = throttle((action: Function) => action(), 350);

export function sleep(durationMs: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, durationMs));
}

export function uuid(): string {
  return v4();
}

export function createArray(length: number): Array<number> {
  return Array.from({ length }, (_, i) => i);
}

export function useLogRenderingCount(name: string): void {
  const renderCountRef = useRef(1);
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.debug(`${name} rendering count:`, renderCountRef.current);
    }
    renderCountRef.current += 1;
  });
}

export function isNullUndefinedOrEmpty(value: any): boolean {
  return value === null || value === undefined || value === '';
}

export function generateRandomBooleanArray(length: number): Array<boolean> {
  return Array.from({ length }, () => Math.random() >= 0.5);
}
