import { atom } from 'jotai';
import {
  selectAtom,
  useAtomCallback,
  useAtomValue,
  useUpdateAtom,
} from 'jotai/utils';
import { useCallback, useEffect, useRef } from 'react';
import { IGridDay } from 'types/events';
import { calculateCalendarTargetDate } from 'utils/time';
import { preferencesAtom } from './preferences/preferencesAtoms';
import { DateTime } from 'luxon';
import { timezoneAtom } from './useTimeZone';
import { gridFocusCurrentTime } from 'components/Grid/GridScrollToCurrentTime';

export const currentDateAtom = atom(DateTime.now());
export const selectedDateAtom = atom(DateTime.now());

export const calendarStartDateAtom = selectAtom(
  atom((get) => {
    const { calendarStartsOn, timezone } = get(preferencesAtom);
    const date = timezone
      ? get(currentDateAtom).setZone(timezone)
      : get(currentDateAtom);
    return calculateCalendarTargetDate(date, calendarStartsOn);
  }),
  (calendarStartOn) => calendarStartOn,
  (prevDate, nextDate) =>
    prevDate.ordinal === nextDate.ordinal &&
    prevDate.year === nextDate.year &&
    prevDate.zoneName === nextDate.zoneName
);

export const calendarEndDateAtom = atom((get) =>
  get(calendarStartDateAtom).plus({ days: 6 }).endOf('day')
);

export const currentWeekAtom = atom((get) => {
  const { calendarStartsOn } = get(preferencesAtom);
  const timezone = get(timezoneAtom);

  const startAt = calculateCalendarTargetDate(
    DateTime.now().setZone(timezone),
    calendarStartsOn
  );

  return {
    startAt,
    endAt: startAt.plus({ days: 6 }),
  };
});

export default function useCalendar() {
  const overnightTimer = useRef(0);
  const startDate = useAtomValue(calendarStartDateAtom);
  const endDate = useAtomValue(calendarEndDateAtom);
  const setStartDate = useUpdateAtom(currentDateAtom);
  const previewDate = useAtomValue(selectedDateAtom);
  const setPreviewDate = useUpdateAtom(selectedDateAtom); // preview date in the month picker (header)

  const timezone = useAtomValue(timezoneAtom);

  useEffect(() => {
    if (overnightTimer.current) {
      return;
    }
    const now = DateTime.now().setZone(timezone);
    overnightTimer.current = window.setTimeout(() => {
      overnightTimer.current = 0;
      setPreviewDate(DateTime.now().setZone(timezone));
      setStartDate(DateTime.now().setZone(timezone));
    }, now.endOf('day').toMillis() + 1 - now.toMillis());
  }, [setStartDate, setPreviewDate, timezone]);

  return {
    previewDate,
    startDate,
    endDate,
  };
}

const calendarDaysAtom = selectAtom(
  atom((get) => {
    const startDate = get(calendarStartDateAtom);
    const endDate = get(calendarEndDateAtom);
    return getDays(startDate, endDate);
  }),
  (days) => days,
  (prev, next) =>
    prev.length === next.length &&
    prev[0]?.date.ordinal === next[0]?.date.ordinal &&
    prev[0]?.date.year === next[0]?.date.year &&
    prev[0]?.date.zoneName === next[0]?.date.zoneName
);

export function useCalendarDays() {
  return useAtomValue(calendarDaysAtom);
}

export function useUpdateCalendar() {
  const setStartDate = useUpdateAtom(currentDateAtom);
  const setPreviewDate = useUpdateAtom(selectedDateAtom);

  const goToToday = useAtomCallback(
    useCallback((get, set) => {
      const timezone = get(timezoneAtom);
      const currentTime = DateTime.now().setZone(timezone);
      if (!currentTime) return;
      const { startAt } = get(currentWeekAtom);

      set(currentDateAtom, startAt);
      set(selectedDateAtom, currentTime); // also move the date in the month picker
      gridFocusCurrentTime();
    }, [])
  );

  const goToNextWeek = useAtomCallback(
    useCallback((get, set) => {
      set(currentDateAtom, (date) => date.plus({ days: 7 }));

      // also move the date in the month picker to the start of this week
      const weekStartDate = get(calendarStartDateAtom);
      set(selectedDateAtom, weekStartDate);
    }, [])
  );

  const goToPrevWeek = useAtomCallback(
    useCallback((get, set) => {
      set(currentDateAtom, (date) => date.minus({ days: 7 }));

      // also move the date in the month picker to the start of this week
      const weekStartDate = get(calendarStartDateAtom);
      set(selectedDateAtom, weekStartDate);
    }, [])
  );
  return {
    goToToday,
    goToPrevWeek,
    goToNextWeek,
    setStartDate,
    setPreviewDate,
  };
}

function getDays(startDate: DateTime, endDate: DateTime): IGridDay[] {
  const daysDiff = Math.ceil(Math.abs(endDate.diff(startDate, 'days').days));

  return Array(daysDiff)
    .fill(null)
    .map((_, index) => ({
      index: index + 1,
      date: startDate.plus({ days: index }),
    }));
}
