// import { HMSRoomProvider } from '@100mslive/hms-video-react';
import { datadogRum } from '@datadog/browser-rum';
import { useUpdateEffect } from '@react-aria/utils';
import ErrorBoundary from 'components/ErrorBoundaries/ErrorBoundary';
import OfflineNotification from 'components/OfflineNotification';
import PageLoading from 'components/PageLoading';
import SegmentAnalytics from 'components/SegmentAnalytics';
import ElectronAutoUpdates from 'components/Electron/ElectronAutoUpdates';
import { SyncTodos } from 'components/Todos/SyncTodos';
import { useIsFirebaseIdValid } from 'hooks/auth/authAtoms';
import { HotkeyProvider } from 'contexts/hotkey';
import { perfMark } from 'contexts/performance';
import { ThemeProvider, ToggleThemeShortcut } from 'contexts/theme';
import { createUrqlClientForHooks } from 'graphql/client';
import { SyncCalendarLinks } from 'hooks/calendarLink/creation/useCalendarLink';
import { SyncCalendars } from 'hooks/useCalendars';
import { SyncContacts } from 'hooks/useContacts';
import { SyncFavorites } from 'hooks/useFavorites';
import { SyncUserPreferences } from 'hooks/usePreferences';
import { SyncTimezone } from 'hooks/useTimeZone';
import React, { useEffect, useState } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import 'styles/index.css';
import { Provider as UrqlProvider } from 'urql';
import { DEEP_LINK_IFRAME_ID } from 'utils/constants';
import { requestIdleCallbackShim } from 'utils/shims';
import type { AppProps } from 'next/app';

requestIdleCallbackShim();

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: '29fbbbd6-915b-4c14-b946-8c87bf166b01',
    clientToken: 'pub06b40e86183e6e16243dd9339cb29598',
    site: 'datadoghq.eu',
    service: 'webapp',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    env: process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT,
    sampleRate: 100,
    trackInteractions: true,
  });
}

function _App({ Component, pageProps }: AppProps) {
  const isTokenValid = useIsFirebaseIdValid();
  const [urqlClient, setUrqlClient] = useState(createUrqlClientForHooks());
  useUpdateEffect(() => {
    if (isTokenValid) {
      setUrqlClient(createUrqlClientForHooks());
    }
  }, [isTokenValid]);

  useEffect(() => {
    perfMark('app-mount');
    // if (process.env.NODE_ENV !== 'production') {
    //   const axe = require('@axe-core/react');
    //   const context = {
    //     exclude: [['#__next-prerender-indicator']],
    //   };
    //   axe(React, ReactDom, 1000, undefined, context);
    // }
  }, []);

  useEffect(() => {
    // Cannot do it the 'react' way, since the class is added in _document.tsx which is server-rendered only
    document.body.classList.remove('preload');
  }, []);

  return (
    <ThemeProvider ssrTheme={pageProps.ssrTheme}>
      <SafariTopSeparator />
      <ErrorBoundary>
        <IntercomProvider
          appId={'zp8wvxrh'}
          initializeDelay={3000}
          shouldInitialize={process.env.NODE_ENV === 'production'}
        >
          <UrqlProvider value={urqlClient}>
            {/* <HMSRoomProvider> */}
            <HotkeyProvider>
              <PageLoading>
                <Component {...pageProps} />

                {process.env.NODE_ENV === 'production' &&
                  process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT !== 'test' && (
                    <SegmentAnalytics />
                  )}

                {/**
                 * NOTE: This is here and not inside <JoinVideoConferenceButton> since the iframe
                 * load will stutter the expanded event view when it's clicked.
                 *
                 * Having one target iframe for all the deep links prevents the layout
                 * thrashing that happend in Firefox.
                 */}
                <iframe
                  title="Deep link placeholder"
                  id={DEEP_LINK_IFRAME_ID}
                  style={{ height: 1, width: 1 }}
                  className="invisible"
                />
              </PageLoading>
              <ToggleThemeShortcut />
              {isTokenValid && (
                <>
                  <SyncCalendars />
                  <SyncContacts />
                  <SyncUserPreferences />
                  <SyncTimezone />
                  <SyncFavorites />
                  <SyncTodos />
                  <SyncCalendarLinks />
                </>
              )}
            </HotkeyProvider>
            {/* </HMSRoomProvider> */}
          </UrqlProvider>
        </IntercomProvider>
      </ErrorBoundary>
      <OfflineNotification />
      <ElectronAutoUpdates />
    </ThemeProvider>
  );
}

function SafariTopSeparator() {
  const [isSafari, setSafari] = useState(false);
  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari) setSafari(true);
  }, []);

  if (isSafari)
    return <div className="border-separator fixed z-[9999] w-full border-t" />;

  return null;
}

export default _App;
