import {
  ReactChild,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { ComboBoxItem } from './ComboBox';
import { DropdownItem } from './Dropdown';

export type PropsOf<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
> = JSX.LibraryManagedAttributes<E, React.ComponentPropsWithRef<E>>;

export function cleanComboBoxItems(items: ComboBoxItem[]): ComboBoxItem[] {
  if (items.every((item) => item.type !== 'option')) return [];

  const lastOption = [...items]
    .reverse()
    .find((item) => item.type === 'option');
  const lastOptionIndex = lastOption ? items.lastIndexOf(lastOption) : 0;

  return items.slice(0, lastOptionIndex + 1);
}

export function cleanDropdownItems(items: DropdownItem[]): DropdownItem[] {
  let visibleItems = items.filter((item) => !item.hidden);
  if (visibleItems.every((item) => item.type !== 'option')) return [];

  visibleItems = visibleItems.reduce((arr, item) => {
    if (item.type === 'separator' && arr[arr.length - 1]?.type === 'title') {
      return arr;
    }
    return [...arr, item];
  }, [] as DropdownItem[]);

  const lastOption = [...visibleItems]
    .reverse()
    .find((item) => item.type === 'option' || item.type === 'custom');
  const lastOptionIndex = lastOption ? visibleItems.lastIndexOf(lastOption) : 0;

  return visibleItems.slice(0, lastOptionIndex + 1);
}

export function isReactText(children: ReactChild): children is React.ReactText {
  return ['string', 'number'].includes(typeof children);
}

export function useMountEffect(onMount: () => void | (() => void)): void {
  return useEffect(() => {
    return onMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useUnmountEffect(onUnmount: () => void): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(() => onUnmount, []);
}

export function spawnParticle(x: number, y: number, content: string): void {
  const particle = document.createElement('div');

  // Calculate a random size from 5px to 25px
  const size = Math.floor(Math.random() * 20 + 5);
  particle.style.width = `${size}px`;
  particle.style.height = `${size}px`;

  // Generate a random x & y destination within a distance of 75px from the mouse
  const destinationX = x + (Math.random() - 0.5) * 2 * 75;
  const destinationY = y + (Math.random() - 0.5) * 2 * 75;
  // particle.style.left = destinationX + 'px';
  // particle.style.top = destinationY + 'px';
  particle.innerHTML = content;
  particle.style.fontSize = `${Math.random() * 24 + 10}px`;
  particle.className = 'fixed z-200 pointer-events-none';
  particle.style.transform = `translate(-50%, -50%) translate(${x}px, ${y}px)`;
  document.body.appendChild(particle);

  // Store the animation in a variable as we will need it later
  const animation = particle.animate(
    [
      {
        // Set the origin position of the particle
        // We offset the particle with half its size to center it around the mouse
        transform: `translate(-50%, -50%) translate(${x}px, ${y}px)`,
        opacity: 1,
      },
      {
        // We define the final coordinates as the second keyframe
        transform: `translate(${destinationX}px, ${destinationY}px)`,
        opacity: 0,
      },
    ],
    {
      duration: Math.random() * 500 + 1000,
      easing: 'cubic-bezier(0, .9, .57, 1)',
      // Delay every particle with a random value of 200ms
    }
  );

  // When the animation is complete, remove the element from the DOM
  animation.onfinish = () => {
    particle.remove();
  };
}
