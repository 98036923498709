import type { EventName, TrackProperties } from 'types/analytics';
import type { User } from 'types/user';

export function setupAnalytics({ uid, displayName, email }: User): void {
  if (!uid) return;
  if (typeof window?.analytics === 'undefined') {
    console.error('Tried setting up analytics before it attached to window');
    return;
  }
  if (window.analyticsUserId === uid) return;
  window.analyticsUserId = uid;
  window.analytics.identify(uid, {
    name: displayName,
    email,
  });
}

export function trackEvent(
  eventName: EventName,
  properties?: TrackProperties
): void {
  if (process.env.NODE_ENV !== 'production') return;

  if (typeof window.analytics === 'undefined') {
    console.warn('No analytics are being sent.');
    return;
  }

  window.analytics.track(eventName, properties);
}
