import { CombinedError } from 'urql';
import create from 'zustand';

export type ErrorStoreState = {
  error: CombinedError | undefined;
  setError: (error: CombinedError) => void;
};

export const useErrorStore = create<ErrorStoreState>((set) => ({
  error: undefined,
  setError: (error) => set(() => ({ error })),
}));
