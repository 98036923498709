import { TodosQuery } from 'graphql/queries/todos.graphql';

export const DEFAULT_DATA: TodosQuery = {
  categories: [],
  todos: [],
};

export const DRAFT = 'draft';
export const SECTION_HEADER_ID = 'todoSectionHeader';

export const PREV = 'prev';
export const NEXT = 'next';

export const ABOVE = 'above';
export const BENEATH = 'beneath';
export const TOP = 'top';
export const BOTTOM = 'bottom';
