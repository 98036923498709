import {
  UserPreferences_Select_Column,
  VideoProvider_Enum,
} from '@graphql-types@';

export const PreferenceName = {
  ...UserPreferences_Select_Column,
};

type PreferenceNameKeys = keyof typeof PreferenceName;
export type PreferenceNameValues = typeof PreferenceName[PreferenceNameKeys];

export const PreferenceVideoProvider = {
  ...VideoProvider_Enum,
};
