import { destroyCookie } from 'nookies';
import { firebase } from 'utils/services/firebaseShared';
import {
  COOKIE_NAME_FIREBASE_ID_TOKEN,
  COOKIE_NAME_OAUTH_TOKEN,
} from 'utils/constants';

const cleanupGraphCache = () =>
  window.indexedDB.deleteDatabase('graphcache-v3');

export async function logout() {
  destroyCookie(null, COOKIE_NAME_FIREBASE_ID_TOKEN);
  destroyCookie(null, COOKIE_NAME_OAUTH_TOKEN);
  cleanupGraphCache();
  await firebase.auth().signOut();
  localStorage.clear();
  window.location.pathname = '/logout';
}
