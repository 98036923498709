import type { NextRouter } from 'next/router';

export const enum Page {
  Root = '/',
  Home = '/home',
  ShareLink = '/s/[id]',
  Login = '/login',
  Support = '/support',
  Popups = '/popups',
}

export function buildUrl(page: Page): string {
  return `/${page}`;
}

export function isRootPage(router: NextRouter): boolean {
  return router.pathname === Page.Root && !isProfilePage(router);
}

export function isHomePage({ pathname }: NextRouter): boolean {
  return pathname === Page.Home;
}

export function isLoginPage({ pathname }: NextRouter): boolean {
  return pathname === Page.Login;
}

export function isShareLinkPage({ pathname }: NextRouter): boolean {
  return pathname === Page.ShareLink;
}

export function isProfilePage({ query }: NextRouter): boolean {
  return typeof query.profile === 'string';
}

export function isSupportPage({ pathname }: NextRouter): boolean {
  return pathname.includes(Page.Support);
}

export function isPopupPage({ pathname }: NextRouter): boolean {
  return pathname.includes(Page.Popups);
}

export function isOnboardingFlagSet({ query }: NextRouter): boolean {
  return query.onboard === 'true';
}
