import { useUser, useUserEmail } from 'hooks/auth/authAtoms';
import {
  ContactsByFavoriteQuery,
  useContactsByFavoriteQuery,
} from 'graphql/queries/contacts.graphql';
import { useContactsByFavoriteSubscription } from 'graphql/subscriptions/contacts.graphql';
import { atomWithStorage, useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useEffect } from 'react';
import { useIsGridEventsReady } from './events/useGridEvents';

export const favoritesAtom = atomWithStorage<
  ContactsByFavoriteQuery['new_contact']
>('favoritesAtom', []);

export default function useFavorites() {
  return useAtomValue(favoritesAtom);
}

export function SyncFavorites(): null {
  const setFavorites = useUpdateAtom(favoritesAtom);
  const user = useUser();
  const userEmail = useUserEmail();
  const pause = user?.didAuth === false;
  const domainEndsWith = `%@${userEmail?.split('@')[1]}`;
  const areGridEventsReady = useIsGridEventsReady();

  const [queryResults] = useContactsByFavoriteQuery({
    pause,
    requestPolicy: 'cache-and-network',
    variables: {
      domainEndsWith: domainEndsWith,
    },
  });

  const [subscriptionResults] = useContactsByFavoriteSubscription({
    pause: pause || !areGridEventsReady,
    variables: {
      domainEndsWith,
    },
  });

  useEffect(() => {
    if (queryResults?.data?.new_contact) {
      setFavorites(queryResults.data.new_contact);
    }
  }, [queryResults?.data?.new_contact, setFavorites]);

  useEffect(() => {
    if (subscriptionResults.data?.new_contact) {
      setFavorites(subscriptionResults.data.new_contact);
    }
  }, [setFavorites, subscriptionResults.data?.new_contact]);

  return null;
}
