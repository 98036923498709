import {
  NewEventVisibilityEnum,
  NewVideoConferenceProvider,
} from '@graphql-types@';
import { getHaveAllOtherGuestsDeclined } from 'components/Grid/utils';
import { CreateEventMutationVariables } from 'graphql/mutations/CreateEvent.graphql';
import { UpdateEventMutationVariables } from 'graphql/mutations/UpdateEvent.graphql';
import { DateTime } from 'luxon';
import { IGridEvent, ServerEvent } from 'types/events';
import { PRIVATE_EVENT_PLACEHOLDER_TITLE } from 'utils/events';
import { dateYearOrdinal } from 'utils/time';
import {
  MEET_LINK_PLACEHOLDER,
  MEET_LINK_PLACEHOLDER_DRAFT,
  ZOOM_LINK_PLACEHOLDER,
} from 'utils/video';
import { v4 as uuidv4 } from 'uuid';

function handleMeetOrZoomOverride(payload: IGridEvent) {
  if (!payload.videoConferences[0]) {
    // Remove previously set video conference links
    return NewVideoConferenceProvider.None;
  }

  if (
    payload.location === MEET_LINK_PLACEHOLDER ||
    payload.location === MEET_LINK_PLACEHOLDER_DRAFT
  ) {
    return NewVideoConferenceProvider.GoogleMeet;
  }
  if (payload.location === ZOOM_LINK_PLACEHOLDER) {
    return NewVideoConferenceProvider.Zoom;
  }

  return undefined;
}

export function formatServerEvent(
  event: ServerEvent,
  userEmail: string,
  timezone: string | undefined
): IGridEvent {
  const startAt = DateTime.fromISO(event.startAt).setZone(timezone);
  const endAt = DateTime.fromISO(event.endAt).setZone(timezone);
  const prevStartAt = DateTime.fromISO(
    event.prevStartAt || event.startAt
  ).setZone(timezone);
  const prevEndAt = DateTime.fromISO(event.prevEndAt || event.endAt).setZone(
    timezone
  );

  const defaultTitle = event.canEdit ? '' : PRIVATE_EVENT_PLACEHOLDER_TITLE;
  return {
    dayIndex: 1,
    ...event,
    title: event.title || defaultTitle,
    recurrenceRules: event.recurrenceRules || [],
    startAt,
    endAt: event.isAllDay
      ? DateTime.max(startAt, endAt.minus({ days: 1 }))
      : DateTime.max(startAt, endAt),
    prevStartAt,
    updatedAt: DateTime.fromISO(event.updatedAt),
    prevEndAt,
    createdAt: new Date(event.createdAt),
    allOtherGuestsDeclined: getHaveAllOtherGuestsDeclined(
      event.attendees,
      userEmail
    ),
    isDraft: false,
  };
}

export const formatUpdateEventPayload = (
  payload: IGridEvent
): UpdateEventMutationVariables => {
  return {
    ...payload,
    eventId: payload.id,
    startAt: payload.startAt ? payload.startAt.toISO() : null,
    endAt: payload.endAt
      ? payload.isAllDay
        ? payload.endAt.plus({ days: 1 }).toISO()
        : payload.endAt.toISO()
      : null,
    // endAtTimezone: timezone,
    addVideoConference: handleMeetOrZoomOverride(payload),
    attendeesEmailAddresses: payload.attendees.map(
      (attendee) => attendee.email
    ),
  };
};
export const formatCreateEventPayload = (
  payload: IGridEvent
): CreateEventMutationVariables => {
  return {
    ...payload,
    startAt: payload.startAt ? payload.startAt.toISO() : null,
    endAt: payload.endAt
      ? payload.isAllDay
        ? payload.endAt.plus({ days: 1 }).toISO()
        : payload.endAt.toISO()
      : null,
    // endAtTimezone: timezone,
    addVideoConference: handleMeetOrZoomOverride(payload),
    attendeesEmailAddresses: payload.attendees.map(
      (attendee) => attendee.email
    ),
    visibility: payload.visibility || NewEventVisibilityEnum.Default,
  };
};

export function generateEventUUID(calendarId: string): string {
  /**
   * We strip out hyphens from UUID because of Google requirement:
   * https://developers.google.com/calendar/api/v3/reference/events/insert
   *
   * "characters allowed in the ID are those used in base32hex encoding,
   *  i.e. lowercase letters a-v and digits 0-9, see section 3.1.2 in RFC2938"
   */
  const eventUUID = uuidv4().replace(/-/gi, '');
  return `${eventUUID}:${calendarId}`;
}

export function isGridEvent(event?: IGridEvent | any): event is IGridEvent {
  return !!event && !!event.id;
}

export function getVisibilityAsEnum(isPrivate?: boolean | null) {
  return isPrivate
    ? NewEventVisibilityEnum.Private
    : NewEventVisibilityEnum.Default;
}

export function getEventDurationMinutes(event: IGridEvent): number {
  return event.endAt.diff(event.startAt).as('minutes');
}

export function sortEventsASC(events: IGridEvent[]): IGridEvent[] {
  return events.sort((a, b) => {
    return a.startAt.diff(b.startAt).as('minutes');
  });
}

export function getFirstAndLastEvents<T extends Pick<IGridEvent, 'startAt'>>(
  events: T[]
): { firstEvent: T; lastEvent: T } | null {
  if (events.length === 0) {
    return null;
  }

  return events.reduce(
    (result, event) => {
      if (eventAisAfterEventB({ eventA: result.firstEvent, eventB: event })) {
        return { firstEvent: event, lastEvent: result.lastEvent };
      }
      if (eventAisAfterEventB({ eventA: event, eventB: result.lastEvent })) {
        return { firstEvent: result.firstEvent, lastEvent: event };
      }
      return result;
    },
    {
      firstEvent: events[0],
      lastEvent: events[events.length - 1],
    }
  );
}

export function eventAisBeforeEventB({
  eventA,
  eventB,
}: {
  eventA: Pick<IGridEvent, 'startAt'>;
  eventB: Pick<IGridEvent, 'startAt'>;
}): boolean {
  return eventA.startAt.toMillis() < eventB.startAt.toMillis();
}

export function eventAisAfterEventB({
  eventA,
  eventB,
}: {
  eventA: Pick<IGridEvent, 'startAt'>;
  eventB: Pick<IGridEvent, 'startAt'>;
}): boolean {
  return eventA.startAt.toMillis() > eventB.startAt.toMillis();
}

export function isBetweenNowAndOneWeekFromNow(event: IGridEvent): boolean {
  const todayOrdinal = dateYearOrdinal(DateTime.now());
  const eventOrdinal = dateYearOrdinal(event.startAt);
  return eventOrdinal <= todayOrdinal + 7 && eventOrdinal >= todayOrdinal - 7;
}
