import { DateTime } from 'luxon';
import { RetrieveSharedCalendarLinkQuery } from 'graphql/queries/calendarLink.graphql';
import { atom } from 'jotai';
import { isEmail } from 'utils/parsing';
import { generateTimeSlots } from 'hooks/calendarLink/helpers/generateTimeSlotsHelper';

export interface CalendarSlotDate {
  id: string;
  calendarLinkId: string;
  recurrenceRules: string[];
  startAt: DateTime;
  endAt: DateTime;
}

export type SharedCalendarLink = Omit<
  RetrieveSharedCalendarLinkQuery['retrieveSharedCalendarLink'],
  'slots'
> & {
  slots: CalendarSlotDate[];
};

export const isConfirmedAtom = atom(false);
export const isShareLinkReadyAtom = atom(false);
export const isShareLinkInvalidAtom = atom(false);
export const shareLinkAtom = atom<SharedCalendarLink | null>(null);

export const selectedTimezoneAtom = atom(
  Intl.DateTimeFormat().resolvedOptions().timeZone
);
export const formNameAtom = atom<string>('');
export const formEmailAtom = atom<string>('');

export const isValidFormAtom = atom((get) => {
  const selectedSlot = get(selectedTimeSlotAtom);
  const formName = get(formNameAtom);
  const formEmail = get(formEmailAtom);
  return formName.trim().length > 0 && isEmail(formEmail) && selectedSlot;
});

export const selectedTimeSlotAtom = atom<CalendarSlotDate | null>(null);
export const selectedDateAtom = atom(DateTime.now());
export const targetDateAtom = atom(DateTime.now());
export const daysRowAtom = atom((get) => {
  const targetDate = get(targetDateAtom);
  return [...Array(7).keys()].map((diff) =>
    targetDate.startOf('day').plus({ days: diff })
  );
});

export const currentTimeSlotsAtom = atom((get) => {
  const selectedDate = get(selectedDateAtom);
  const shareLinkData = get(shareLinkAtom);

  return generateTimeSlots({
    day: selectedDate,
    slots: shareLinkData?.slots || [],
    durationMinutes: shareLinkData?.eventMinDurationMinutes || 30,
    bufferDurationMinutes: shareLinkData?.bufferDurationMinutes ?? 0,
  });
});

export const startTimeAtom = atom((get) => {
  const currentTimeSlots = get(currentTimeSlotsAtom);
  let minHours = Infinity;

  currentTimeSlots?.forEach((slot) => {
    const hours = slot.startAt.get('hour');
    minHours = Math.min(minHours, hours);
  });

  if (minHours === Infinity) {
    minHours = 9;
  }

  return DateTime.fromObject({
    hour: Math.max(minHours, 0),
    minute: 0,
    second: 0,
    millisecond: 0,
  });
});

export const endTimeAtom = atom((get) => {
  const currentTimeSlots = get(currentTimeSlotsAtom);
  let maxHours = 0;

  currentTimeSlots?.forEach((slot) => {
    const hours = slot.endAt.get('hour');
    maxHours = Math.max(maxHours, hours === 0 ? 24 : hours);
  });

  if (maxHours === 0) {
    maxHours = 18;
  }

  return DateTime.fromObject({
    hour: Math.min(maxHours, 24),
    minute: 0,
    second: 0,
    millisecond: 0,
  });
});
