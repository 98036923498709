import { NormalizedPreferences, withPreferenceDefaults } from 'utils/format';
import { atomWithStorage, selectAtom } from 'jotai/utils';

export const preferencesAtom = atomWithStorage<NormalizedPreferences>(
  'preferencesAtom',
  withPreferenceDefaults(undefined)
);

export const isPreferencesReadyAtom = atomWithStorage(
  'preferencesReadyAtom',
  false
);

export const hasOnboardedAtom = selectAtom(
  preferencesAtom,
  (preferences) => preferences.hasOnboarded
);

export const ui24HourClockAtom = selectAtom(
  preferencesAtom,
  (preferences) => preferences.ui24HourClock
);

export const hideDeclinedEventsAtom = selectAtom(
  preferencesAtom,
  (preferences) => preferences.hideDeclinedEvents
);

export const showDoneTodosAtom = selectAtom(
  preferencesAtom,
  (preferences) => preferences.showDoneTodos
);

export const accentColorAtom = selectAtom(
  preferencesAtom,
  (preferences) => preferences.defaultEventColor
);

export const userThemeAtom = selectAtom(
  preferencesAtom,
  (preferences) => preferences.theme
);
