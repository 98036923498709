import { Getter, Setter } from 'jotai';
import { IGridEvent } from 'types/events';
import {
  eventIdsPoolAtom,
  gridEventsFamily,
  optimisticEventsFamily,
  serverEventsAtomFamily,
} from '../eventAtoms';
import { isGridEvent } from './eventsHelpers';

export function removeEventIdFromPool(
  set: Setter,
  eventCompoundId: string
): void {
  set(eventIdsPoolAtom, (prevIds) => {
    prevIds.delete(eventCompoundId);
    return new Set(prevIds);
  });
}

export function removeEventFromPool(
  set: Setter,
  eventCompoundId: string
): void {
  removeEventIdFromPool(set, eventCompoundId);
  optimisticEventsFamily.remove(eventCompoundId);
  serverEventsAtomFamily.remove(eventCompoundId);
}

export function markEventAsCancelled(
  set: Setter,
  eventCompoundId: string
): void {
  removeEventIdFromPool(set, eventCompoundId);
  set(optimisticEventsFamily(eventCompoundId), (prev) => ({
    ...prev,
    status: 'cancelled',
  }));
}

type PoolType = 'server' | 'optimistic' | 'server-and-optimistic';

export function addEventToPool(
  set: Setter,
  event: IGridEvent,
  type: PoolType
): void {
  set(eventIdsPoolAtom, (prevIds) => {
    prevIds.add(event.id);
    return new Set(prevIds);
  });
  if (type === 'server' || type === 'server-and-optimistic') {
    set(serverEventsAtomFamily(event.id), event);
    set(optimisticEventsFamily(event.id), null);
  }
  if (type === 'optimistic' || type === 'server-and-optimistic') {
    set(optimisticEventsFamily(event.id), (previous) => ({
      ...previous,
      ...event,
      status: 'confirmed',
    }));
  }
}

export function addBatchEvents(
  set: Setter,
  eventsList: IGridEvent[],
  type: PoolType = 'server-and-optimistic'
): void {
  eventsList.forEach((event) => {
    addEventToPool(set, event, type);
  });
}

export function getGridEventsPool(get: Getter): IGridEvent[] {
  const eventIds = [...get(eventIdsPoolAtom)];
  return eventIds.map((id) => get(gridEventsFamily(id))).filter(isGridEvent);
}
