/* a1b36b6a362ee4fa86ea479e360d2b719b1c9929
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateContactMutationVariables = Types.Exact<{
  userId: Types.Scalars['String'];
  email: Types.Scalars['String'];
  set?: Types.InputMaybe<Types.New_Contact_Set_Input>;
}>;


export type UpdateContactMutation = { __typename?: 'mutation_root', update_new_contact?: { __typename?: 'new_contact_mutation_response', affected_rows: number } | null };

export type UpdateContactByPkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  _set?: Types.New_Contact_Set_Input;
}>;


export type UpdateContactByPkMutation = { __typename?: 'mutation_root', update_new_contact_by_pk?: { __typename?: 'new_contact', avatar?: string | null, displayName?: string | null, emailAddress: string, firstName?: string | null, lastName?: string | null, userId: string, reminderEventId?: string | null, favoriteAt?: any | null, labels: any, createdAt: any, id: any, lastInteractedAt: any, updatedAt: any, notes?: string | null } | null };

export type SetContactReminderMutationVariables = Types.Exact<{
  contactId: Types.Scalars['String'];
  recurrenceRules: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type SetContactReminderMutation = { __typename?: 'mutation_root', setReminder: { __typename?: 'SetReminderOutput', event?: { __typename?: 'NewEvent', id: string, calendarId: string, recurrenceRules?: Array<string> | null, recurringEventId?: string | null, title: string, visibility: Types.NewEventVisibilityEnum, description?: string | null } | null } };

export type SetBirthdayWithReminderMutationVariables = Types.Exact<{
  contactId: Types.Scalars['String'];
  birthday?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SetBirthdayWithReminderMutation = { __typename?: 'mutation_root', setBirthdayReminder: { __typename?: 'SetBirthdayReminderOutput', event?: { __typename?: 'NewEvent', id: string } | null } };


export const UpdateContactDocument = gql`
    mutation UpdateContact($userId: String!, $email: String!, $set: new_contact_set_input) {
  update_new_contact(
    _set: $set
    where: {userId: {_eq: $userId}, emailAddress: {_eq: $email}}
  ) {
    affected_rows
  }
}
    `;

export function useUpdateContactMutation() {
  return Urql.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument);
};
export const UpdateContactByPkDocument = gql`
    mutation UpdateContactByPk($id: uuid!, $_set: new_contact_set_input! = {}) {
  update_new_contact_by_pk(pk_columns: {id: $id}, _set: $_set) {
    avatar
    displayName
    emailAddress
    firstName
    lastName
    userId
    reminderEventId
    favoriteAt
    labels
    createdAt
    id
    lastInteractedAt
    updatedAt
    notes
  }
}
    `;

export function useUpdateContactByPkMutation() {
  return Urql.useMutation<UpdateContactByPkMutation, UpdateContactByPkMutationVariables>(UpdateContactByPkDocument);
};
export const SetContactReminderDocument = gql`
    mutation SetContactReminder($contactId: String!, $recurrenceRules: [String!]!) {
  setReminder(objects: {contactId: $contactId, recurrenceRules: $recurrenceRules}) {
    event {
      id
      calendarId
      recurrenceRules
      recurringEventId
      title
      visibility
      description
    }
  }
}
    `;

export function useSetContactReminderMutation() {
  return Urql.useMutation<SetContactReminderMutation, SetContactReminderMutationVariables>(SetContactReminderDocument);
};
export const SetBirthdayWithReminderDocument = gql`
    mutation SetBirthdayWithReminder($contactId: String!, $birthday: String) {
  setBirthdayReminder(objects: {contactId: $contactId, birthday: $birthday}) {
    event {
      id
    }
  }
}
    `;

export function useSetBirthdayWithReminderMutation() {
  return Urql.useMutation<SetBirthdayWithReminderMutation, SetBirthdayWithReminderMutationVariables>(SetBirthdayWithReminderDocument);
};