import { useCallback } from 'react';
import useElectronIpc, { useElectronIpcEvent } from 'hooks/useElectronIpc';
import useToaster from 'joy/Toasts/useToaster';

const ElectronAutoUpdates = () => {
  const { ipc } = useElectronIpc();
  const { add, remove } = useToaster();
  const toastId = 'auto-update-ready';

  const handleInstall = useCallback(() => {
    remove(toastId);
    ipc?.send('update:install');
  }, [remove, ipc]);

  const handleUpdateReady = useCallback(
    () =>
      add({
        id: 'auto-update-ready',
        label: 'New version available',
        description: 'Want to install it?',
        kind: 'success',
        autoHide: false,
        actions: [
          {
            label: 'Quit and install',
            onClick: handleInstall,
          },
          {
            label: 'On next launch',
            onClick: () => {
              remove(toastId);
            },
          },
        ],
      }),
    [add, remove, handleInstall]
  );

  useElectronIpcEvent('update:ready', handleUpdateReady);

  return null;
};

export default ElectronAutoUpdates;
