import fastDeepEqual from 'fast-deep-equal';
import { calendarEndDateAtom, calendarStartDateAtom } from 'hooks/useCalendar';
import { timezoneAtom } from 'hooks/useTimeZone';
import { calendarsWithDefaultAtom } from 'hooks/useVisibleCalendars';
import { atom } from 'jotai';
import { atomFamily, selectAtom } from 'jotai/utils';
import { DateTime } from 'luxon';
import { IGridEvent } from 'types/events';
import { isGridEvent } from './helpers/eventsHelpers';

// TODO: How can we do these with params given to the atom instead? Maybe event family where
// the week offset is provided..?
export const nextWeekEventsIds = atom<string[]>((get) => {
  const calendarIds = get(calendarsWithDefaultAtom);
  const startAt = get(calendarStartDateAtom).plus({ weeks: 1 });
  const endAt = get(calendarEndDateAtom).plus({ weeks: 1 });
  const serverEventIds = [...get(eventIdsPoolAtom)];
  const visibleIds = serverEventIds.filter((id) => {
    const event = get(gridEventsFamily(id));
    return (
      event &&
      event.status !== 'cancelled' &&
      event.startAt.toMillis() >= startAt.toMillis() &&
      event.endAt.toMillis() <= endAt.toMillis() &&
      calendarIds.includes(event.calendarId)
    );
  });
  return visibleIds;
});

export const visibleEventsIdsAtom = selectAtom(
  atom<string[]>((get) => {
    const calendarIds = get(calendarsWithDefaultAtom);
    const startAt = get(calendarStartDateAtom);
    const endAt = get(calendarEndDateAtom);
    const serverEventIds = [...get(eventIdsPoolAtom)];
    const visibleIds = serverEventIds.filter((id) => {
      const event = get(gridEventsFamily(id));
      return (
        event &&
        event.status !== 'cancelled' &&
        event.startAt.toMillis() >= startAt.toMillis() &&
        event.endAt.toMillis() <= endAt.toMillis() &&
        calendarIds.includes(event.calendarId)
      );
    });
    return visibleIds;
  }),
  (ids) => ids,
  (prevIds, nextIds) => fastDeepEqual(prevIds, nextIds)
);

/**
 * Returns an atom family that contains the ids of the events that are visible including the optimistic updates
 */
export const alldayVisibleServerEvents = atom<IGridEvent[]>((get) => {
  const ids = get(visibleEventsIdsAtom);
  return ids
    .map((id) => get(serverEventsAtomFamily(id)))
    .filter(isGridEvent)
    .filter((event) => event.isAllDay);
});

export const visibleEventsAtom = atom<IGridEvent[]>((get) => {
  const ids = get(visibleEventsIdsAtom);
  return ids.map((id) => get(gridEventsFamily(id))).filter(isGridEvent);
});

export const nextWeekEventsAtom = atom<IGridEvent[]>((get) => {
  const ids = get(nextWeekEventsIds);
  return ids.map((id) => get(gridEventsFamily(id))).filter(isGridEvent);
});

/**
 * Returns an atom family that contains the ids of the events that are visible but without the optimistic updates
 */
export const visibleServerEventsAtom = atom<IGridEvent[]>((get) => {
  const ids = get(visibleEventsIdsAtom);
  return ids.map((id) => get(gridEventsFamily(id))).filter(isGridEvent);
});

export const isGridEventsReadyAtom = atom(false);
export const eventIdsPoolAtom = atom(new Set<string>());
export const serverEventsAtomFamily = atomFamily(() =>
  atom<IGridEvent | null>(null)
);
export const optimisticEventsFamily = atomFamily(() =>
  atom<Partial<IGridEvent> | null>(null)
);

/**
 * This atom can be used to provide updates on user interaction (dragging, resizing, etc)
 * Note: It won't be part of the optimistic events family as long as it's not committed
 */
export const interactionOnlyEventsFamily = atomFamily(() =>
  atom<{ startAt: DateTime; endAt: DateTime } | null>(null)
);

export const optimisticMutationTimestamps = atomFamily(() =>
  atom<Partial<number> | null>(null)
);
export const gridEventsFamily = atomFamily(
  (id: string) =>
    atom(
      // Getter
      (get) => {
        const timezone = get(timezoneAtom);
        const serverEvent = get(serverEventsAtomFamily(id));
        const optimisticEvent = get(optimisticEventsFamily(id));
        const mergedEvent = {
          ...serverEvent,
          ...optimisticEvent,
        } as IGridEvent;

        if (mergedEvent.attendees) {
          mergedEvent.rsvpForCalendarId = mergedEvent.attendees.find(
            (attendee) => attendee.email === mergedEvent.calendarId
          )?.RSVP;
        }

        mergedEvent.startAt = mergedEvent.startAt?.setZone(timezone);
        mergedEvent.prevStartAt = mergedEvent.prevStartAt?.setZone(timezone);
        mergedEvent.endAt = mergedEvent.endAt?.setZone(timezone);
        mergedEvent.prevEndAt = mergedEvent.prevEndAt?.setZone(timezone);

        return mergedEvent.id ? mergedEvent : null;
      },
      // Setter
      (get, set, update) => {
        const newValue =
          typeof update === 'function'
            ? update(get(optimisticEventsFamily(id)))
            : update;
        return set(optimisticEventsFamily(id), newValue);
      }
    )
  // optional isEqual method -> see deepEqual from 'fast-deep-equal'
);
